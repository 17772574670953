<template>

<div>

<!-- No vistor is waiting currently -->
<div v-if="!state.visitor" class="card h-100">

  <!-- Wating for the call to start: Shown remaining Time -->
  <div v-if="state.waitingForCall" class="m-auto text-center p-2">
    <i class="bi bi-calendar-check fs-2 text-primary"></i>
    <div class="fs-5 text-muted">Termin startet in</div>
    <div class="fs-4 mt-4">{{ state.waitingTime }}</div>
  </div>

  <!-- Appointment has started (based on time), but patient has not dialed in yet entered: Show waiting time -->
  <div v-if="state.waitingForPatientToDialIn" class="m-auto text-center p-2">
    <i class="bi bi-telephone fs-2 text-primary"></i>
    <div class="fs-5 text-muted">Der Patient hat sich noch nicht eingewählt</div>
    <div class="fs-4 mt-4">Warten seit {{ state.waitingTime }}</div>
    <a class="btn btn-primary mt-4" target="meeting" :href="getMeetingUrl()">Gespräch starten</a>
  </div>

  <!-- Appointment is overdue / was not conducted (after given time) -->
  <div v-if="state.callIsOverdue" class="mt-4 lead p-3">
    <div class="text-center"><i class="bi bi-exclamation-triangle fs-2 text-primary"></i></div>
    <div class="fs-5 text-muted">Der Termin wurde nicht per Video durchgeführt. Konnten Sie den Termin telefonisch durchführen?</div>
    <div class="mt-4">
      <button class="btn btn-primary w-100" @click="setAppointmentConductedByPhone()">Ja, per Telefon</button>
      <button class="btn btn-secondary w-100 mt-2" @click="setAppointmentCancelled()">Nein, Patient war nicht erreichbar</button>
    </div>
  </div>

</div>


<!-- Visitor entered the call  -->
<div v-if="state.visitor" class="card h-100">

  <img v-if="state.visitor.patient.gender == 'Male'" src="../../assets/user-male.svg" style="width:6rem;height:6rem" class="mx-auto rounded-circle border border-primary border-3 mt-3">
  <img v-if="state.visitor.patient.gender == 'Female'" src="../../assets/user-female.svg" style="width:6rem;height:6rem" class="mx-auto rounded-circle border border-primary border-3 mt-3">
  <img v-if="state.visitor.patient.gender == 'Diverse'" src="../../assets/user-diverse.svg" style="width:6rem;height:6rem" class="mx-auto rounded-circle border border-primary border-3 mt-3">

  <div class="card-body text-center">

    <h5 class="card-title">{{ state.visitor.patient.name + ' ' + state.visitor.patient.familyName }}</h5>
    <h6 class="card-subtitle mb-2 text-muted">{{ $fmt.fromFormatToDate(state.visitor.patient.birthdate, "yyyy-MM-dd") }}</h6>

    <div class="mt-4">
        <i class="bi-calendar2-check" role="img" aria-label="Schedule"></i>
        <span class="ms-2">{{ $fmt.fromMillisToDateTime(state.visitor.slotAt) }}</span>
    </div>

    <div v-if="!state.visitor.acceptedAt" class="mt-2">
      <div class="spinner-grow spinner-grow-sm text-primary" role="status"></div>
      <span class="align-middle ms-2">Seit {{ state.visitor.waiting }} bereit</span>
    </div>

  </div>

  <div class="card-body text-center">

    <a v-if="!state.visitor.acceptedAt" :href="state.visitor.meetingUrl" target="confrere" class="btn btn-primary">
      <i class="bi-telephone me-2" role="img" aria-label="Schedule"></i>
      Annehmen
    </a>

    <a v-if="state.visitor.acceptedAt" href="#" class="btn btn-primary disabled">
      <i class="bi-telephone me-2" role="img" aria-label="Schedule"></i>
      Angenommen
    </a>

  </div>

</div>
</div>

</template>

<script>

import { reactive, watch } from 'vue'

import api from '@/modules/api.js';
import format from '@/modules/format.js';
import log from '@/modules/logging.js';
import utils from '@/modules/utils.js';
import logging from '../../modules/logging';

export default {
  name: 'CallStatus',
  emits: [
    'onUpdate'
  ],
  props: {
    appointment: Object
  },
  setup(props, { emit} ) {

    const state = reactive({
      
      subscriberId: utils.uuid(),
      polling: true,

      visitor: null,
      interval: null,

      waitingTime: null,
      waitingForCall: false,
      waitingForPatientToDialIn: false,
      callIsOverdue: false,
    });


    function pollVisitor(visitorUuid) {
      if (state.polling) {
        api.pollVisitor(state.subscriberId, visitorUuid).
          then(response => {
            // poll returns array, so just use the first and only one
            logging.trace("CallStatus:pollVisitor() Polled visitors: {}", response);
            postProcessResponse(response.length == 1 ? response[0] : null);
            pollVisitor(visitorUuid);
          }).catch(() => {
            pollVisitor(visitorUuid);
          });
      }
    }

    function postProcessResponse(visitor) {

      log.trace("CallStatus:postProcessResponse() Visitor: {}", visitor);

      //
      // if visitor status changed, fire update event
      log.trace("CallStatus:postProcessResponse() current visitor: {}, new visitor: {}", state.visitor, visitor);
      if (state.visitor && visitor) {
        if (state.visitor.status != visitor.status) {
          log.trace("CallStatus:postProcessResponse() Visitor status changed from {} to {}", state.visitor.status, visitor.status);
          emit('onUpdate', state.visitor.status, visitor.status);
        }
      }

      //
      // reset visitor
      state.visitor = visitor;

      //
      // clear current interval
      if (state.interval) {
        clearInterval(state.interval);
        state.interval = null;
      }

      //
      // install interval 
      if (state.visitor) {
        state.interval = setInterval(() => {
            state.visitor.waiting = format.toDuration(state.visitor.enteredAt, new Date().getTime());
        }, 1000);
      }
      else {
        state.interval = setInterval(() => {
            if (new Date().getTime() < props.appointment.slotAt) {

              state.waitingTime = format.toDuration(new Date().getTime(), props.appointment.slotAt, false);

              state.waitingForCall = true;
              state.waitingForPatientToDialIn = false;
              state.callIsOverdue = false;
            }
            else {

              state.waitingTime = format.toDuration(props.appointment.slotAt, new Date().getTime(), false);

              let diff = new Date().getTime() - props.appointment.slotAt;

              state.waitingForCall = false;
              state.waitingForPatientToDialIn = diff < 30 * 60 * 1000;
              state.callIsOverdue = diff > 30 * 60 * 1000;
            }
        }, 1000);
      }
    }

    function setAppointmentConductedByPhone() {
      api.setAppointmentStatus(props.appointment.uuid, { status: 'CallEstablished'}).
        then(() => {
          emit('onUpdate');
        });
    }

    function setAppointmentCancelled() {
      api.setAppointmentStatus(props.appointment.uuid, { status: 'Cancelled'}).
        then(() => {
          emit('onUpdate');
        });
    }

    function getMeetingUrl() {
      return process.env.VUE_APP_API_ENDPOINT + '/public/v1/appointments/' + props.appointment.uuid + '/meetingUrl';
    }

    //
    // query visitor
    pollVisitor(props.appointment.uuid);
    watch(() => props.appointment, (appointment) => { pollVisitor(appointment.uuid); });

    return {
      state,
      setAppointmentConductedByPhone,
      setAppointmentCancelled,
      getMeetingUrl,
    };
  },
  beforeUnmount() {

    // stop polling for visitor updates
    this.state.polling = false;

    // stop updating the duration of the calls
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  },
};
</script>
<template>

<div class="modal" ref="newSlotDialog" tabindex="-1" aria-labelledby="newSlotDialogLabel" aria-hidden="true">
<div class="modal-dialog modal-xl">
  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">Neue Sprechstunde</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="modal-body">

    <form>

      <!-- Start -->          
      <div class="row">
        <div class="col">
          <label for="inputName" class="form-label">Datum</label>
          <input v-model="state.newSlot.startDate" type="date" class="form-control" :class="{ 'is-invalid' : state.newSlot.validation.startDate }" required>
          <div class="invalid-feedback">{{state.newSlot.validation.startDate}}</div>
        </div>
        <div class="col">
          <label for="inputName" class="form-label">Von</label>
          <input v-model="state.newSlot.startTime" type="time" class="form-control" :class="{ 'is-invalid' : state.newSlot.validation.startTime }" required>
          <div class="invalid-feedback">{{state.newSlot.validation.startTime}}</div>
        </div>
        <div class="col">
          <label for="inputName" class="form-label">Bis</label>
          <input v-model="state.newSlot.endTime" type="time" class="form-control" :class="{ 'is-invalid' : state.newSlot.validation.endTime }">
          <div class="invalid-feedback">{{state.newSlot.validation.endTime}}</div>
        </div>
      </div>

      <!-- Recurring -->
      <div class="mt-4" id="v-model-radiobutton">
        <div class="form-check form-check-inline">
          <input v-model="state.newSlot.recurringType" class="form-check-input" type="radio" name="recurringType" id="radioRecurringNone" value="None">
          <label class="form-check-label" for="radioRecurringNone">Einmalig</label>
        </div>
        <div class="form-check form-check-inline">
          <input v-model="state.newSlot.recurringType" class="form-check-input" type="radio" name="recurringType" id="radioRecurringWeek" value="Weekly">
          <label class="form-check-label" for="radioRecurringWeek">Jeden <span class="fw-bold">{{ WEEKDAYS[state.newSlot.startDateWeekday].deLong }}</span> wiederholen</label>
        </div>
        <div class="form-check form-check-inline mb-3">
          <input v-model="state.newSlot.recurringType" class="form-check-input" type="radio" name="recurringType" id="radioRecurringCustom" value="Custom">
          <label class="form-check-label" for="radioRecurringCustom">An ausgewählten Tagen wiederholen</label>
        </div>
      </div>

      <!-- Custom Recurring Days -->
      <div v-if="state.newSlot.recurringType == 'Custom'" class="mt-2 btn-group" role="group" aria-label="Basic checkbox toggle button group">
        <div class="col me-2" v-for="day in WEEKDAYS" :key="day">
          <input v-model="state.newSlot.recurringWeekdays" :value="day.value" type="checkbox" class="btn-check" :id="'btn-' + day.de" autocomplete="off">
          <label class="btn btn-outline-primary rounded" :for="'btn-' + day.de">{{ day.de }}</label>
        </div>
        <div class="invalid-feedback" :class="{ 'd-block' : state.newSlot.validation.recurringWeekdays }">{{state.newSlot.validation.recurringWeekdays}}</div>
      </div>

      <!-- Recurring End -->
      <div v-if="state.newSlot.recurringType != 'None'" class="mt-4" id="v-model-radiobutton">
        <div class="form-check form-check-inline">
          <input v-model="state.newSlot.recurringEndType" class="form-check-input" type="radio" name="endType" id="radioEndTypeNever" value="Never">
          <label class="form-check-label" for="radioEndTypeNever">Endet nie</label>
        </div>
        <div class="form-check form-check-inline">
          <input v-model="state.newSlot.recurringEndType" class="form-check-input" type="radio" name="endType" id="radioEndTypeDay" value="Day">
          <label class="form-check-label" for="radioEndTypeDay">Endet am</label>
        </div>
        <div class="form-check form-check-inline">
          <input v-model="state.newSlot.recurringEndDate" type="date" class="form-control" :class="{ 'is-invalid' : state.newSlot.validation.recurringEndDate }" :disabled="state.newSlot.recurringEndType == 'Never'">
          <div class="invalid-feedback">{{ state.newSlot.validation.recurringEndDate }}</div>
        </div>
      </div>

    </form>
    </div>  

    <div class="modal-footer">              
      <button class="btn btn-primary mt-4" @click="createNewSlot">Sprechstunden eintragen</button>
    </div>
  </div>

</div>
</div>

</template>

<script>

import { computed, reactive, ref } from 'vue'

import { Modal } from 'bootstrap'
import { DateTime } from  'luxon';

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'NewSlot',
  emits: [
    'onClosed'
  ],
  created() {
    this.WEEKDAYS = [
      { de: "Mo", deLong: "Montag",     value: 0 },
      { de: "Di", deLong: "Dienstag",   value: 1 },
      { de: "Mi", deLong: "Mittwoch",   value: 2 },
      { de: "Do", deLong: "Donnerstag", value: 3 },
      { de: "Fr", deLong: "Freitag",    value: 4 },
      { de: "Sa", deLong: "Samstag",    value: 5 },
      { de: "So", deLong: "Sonntag",    value: 6 }
    ];
  },
  setup(props, { emit }) {

    const DATE_FORMAT = "yyyy-MM-dd";
    // const TIME_FORMAT = "HH:mm";
    const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";
    const DATE_TIME_FORMAT_2 = "yyyy-MM-dd HH:mm:ss";

    const newSlotDialog = ref(null);
    let newSlotModal = null;

    const state = reactive({

      newSlot: {

        startDate: DateTime.now().toFormat(DATE_FORMAT),
        startTime: null,
        endTime: null,

        startDateWeekday: computed(() => {
          return toMonday(DateTime.fromFormat(state.newSlot.startDate, DATE_FORMAT).weekday);
        }),

        recurringType: 'None',
        recurringWeekdays: [],
        recurringEndType: 'Never',
        recurringEndDate: null,

        validation: {

          startDate: null,
          startTime: null,
          endTime: null,
          recurringWeekdays: null,
          recurringEndDate: null,

          isValid: computed(() => {
            return !state.newSlot.validation.startDate && 
              !state.newSlot.validation.startTime && 
              !state.newSlot.validation.endTime &&
              !state.newSlot.validation.recurringWeekdays &&
              !state.newSlot.validation.recurringEndDate; }),
        },
      },
    });

    function show() {

      //
      // init new slot
      state.newSlot.startDate = DateTime.now().toFormat(DATE_FORMAT);
      state.newSlot.startTime = null;
      state.newSlot.endTime = null;
      state.newSlot.recurringType = 'None';
      state.newSlot.recurringWeekdays = [];
      state.newSlot.recurringEndType = 'Never';
      state.newSlot.recurringEndDate = null;

      //
      // reset validation
      state.newSlot.validation.startDate = null;
      state.newSlot.validation.startTime = null;
      state.newSlot.validation.endTime = null;
      state.newSlot.validation.recurringWeekdays = null;
      state.newSlot.validation.recurringEndDate = null;

      //
      // show as modal dialog
      newSlotModal = new Modal(newSlotDialog.value);
      newSlotModal.show();
    }

    function createNewSlot() {

      //
      // validate new slot first
      if (!validateNewSlot()) {
        log.trace("NewSlot:createNewSlot() New slot validation result: {}", state.newSlot.validation);
        return;
      }

      //
      // transform to new slot structure
      api.createSlotTemplate(prepareNewSlot()).then(() => { emit('onClosed'); });

      //
      // hide dialog
      newSlotModal.hide();
      newSlotModal = null;
    }

    function prepareNewSlot() {

      let newSlot = { 
        "startAt": DateTime.fromFormat(state.newSlot.startDate + ' ' + state.newSlot.startTime, DATE_TIME_FORMAT).toUTC().toMillis(),
        "endAt": DateTime.fromFormat(state.newSlot.startDate + ' ' + state.newSlot.endTime, DATE_TIME_FORMAT).toUTC().toMillis(),
        "recurringWeekdays": [],
        "recurringEndDate": state.newSlot.recurringEndType == 'Day' ?  state.newSlot.recurringEndDate : null
      };

      if (state.newSlot.recurringType == 'Weekly') {
        newSlot.recurringWeekdays = [ toMonday(new Date(state.newSlot.startDate).getDay()) ];
      }
      else if (state.newSlot.recurringType == 'Custom') {
        newSlot.recurringWeekdays = state.newSlot.recurringWeekdays;
      }

      log.trace("NewSlot:prepareNewSlot() Prepared new slot: {}", newSlot);
      return newSlot;
    }

    function validateNewSlot() {

      log.trace("NewSlot:validateNewSlot() Validating new slot: {} ", state.newSlot);

      //
      // reset validation object
      state.newSlot.validation.startDate = null;
      state.newSlot.validation.startTime = null;
      state.newSlot.validation.endTime = null;
      state.newSlot.validation.recurringWeekdays = null;
      state.newSlot.validation.recurringEndDate = null;

      //
      // validate all required fields
      state.newSlot.validation.startDate = 
        !state.newSlot.startDate ? 'Bitte geben Sie ein Startdatum an.' : null;
      state.newSlot.validation.startTime = 
        !state.newSlot.startTime ? 'Bitte geben Sie eine Startzeit an.' : null;
      state.newSlot.validation.endTime = 
        !state.newSlot.endTime ? 'Bitte geben Sie eine Endzeit an.' : null;
      state.newSlot.validation.recurringWeekdays = 
        state.newSlot.recurringType == 'Custom' && state.newSlot.recurringWeekdays.length == 0 ? 'Bitte wählen Sie mindestens einen Wockentag aus' : null;
      state.newSlot.validation.recurringEndDate =
        state.newSlot.recurringType != 'None' && state.newSlot.recurringEndType == 'Day' && !state.newSlot.recurringEndDate ? 'Bitte wählen Sie eine Ende Datum aus' : null;

      //
      // if any vlidations failes until here - stop and display errors first
      if (!state.newSlot.validation.isValid) {
        return false;
      }

      //
      // validate, that start is in the future
      let startAt = DateTime.fromFormat(state.newSlot.startDate + ' ' + state.newSlot.startTime, DATE_TIME_FORMAT);
      state.newSlot.validation.startDate = startAt < Date.now() ? 'Startzeitpunkt muss in der Zukunft liegen' : null;

      //
      // validate, that end time is bigger than start time
      let endAt = DateTime.fromFormat(state.newSlot.startDate + ' ' + state.newSlot.endTime, DATE_TIME_FORMAT);
      state.newSlot.validation.endTime = (endAt - startAt < 1000 * 60 * 30) ? 'Sprechstunde muss min. 30 min lang sein' : null;

      //
      // validate, that end date is biggert than start date (min 24h)
      if (state.newSlot.recurringType != 'None' && state.newSlot.recurringEndType == 'Day') {
        let endRecurringAt = DateTime.fromFormat(state.newSlot.recurringEndDate + " 23:59:59", DATE_TIME_FORMAT_2);
        state.newSlot.validation.recurringEndDate = (endRecurringAt - startAt < 1000 * 60 * 60 * 24) ? 'Enddatum muss mindestens 24h in der Zukunft liegen' : null;
      }

      //
      // return validation state
      return state.newSlot.validation.isValid;
    }

    function toMonday(day) {
      return day == 0 ? 6 : day -1;
    }

    return {
      newSlotDialog,
      state,
      show,
      createNewSlot
    };
  }
};
</script>

<style scoped>
</style>
<template>

<!-- EHR -->
<div class="f-flex mt-5 mb-4 d-flex align-items-center">

  <div class="fs-4 lead me-auto">Gesundheitsakte</div>

  <div class="dropdown">
    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      Hinzufügen
    </button>
    <ul class="dropdown-menu">
      <li><a class="dropdown-item" href="#healthrecords" @click="showNewNote(true)">Neue Notitz</a></li>
      <li><a class="dropdown-item" href="#healthrecords" @click="showUploadFile(true)">Neues Dokument</a></li>
    </ul>
  </div>

</div>

<!-- New Note -->
<transition>
<div v-if="state.showForm == FORMS.NewNote" class="my-4 p-3 border shadow">
  <div class="fs-4 lead">Notiz hinzufügen</div>
  <textarea v-model="state.newNoteText" class="form-control my-4" placeholder="Notiz erfassen" aria-label="Recipient's username" aria-describedby="button-addon2">
  </textarea>
  <button class="btn btn-primary" :disabled="state.newNoteText == ''" type="button" id="button-addon2" @click="saveNewNote()">Notiz hinzufügen</button>
  <button class="btn btn-outline-secondary ms-2" type="button" id="button-addon2" @click="showNewNote(false)">Verwerfen</button>
</div>
</transition>

<!-- New Document -->
<transition>
<div v-if="state.showForm == FORMS.FileUpload" class="my-4 p-3 border shadow">
  <div class="fs-4 lead">Dokument hinzufügen</div>

  <div class="my-4">

    <select class="form-select" v-model="state.fileType" aria-label="Default select example" required>
      <option disabled value="">Bitte Dokumententyp auswählen...</option>
      <option value="LabReport">Laborergebnisse</option>
      <option value="DischargeLetter">Arztbrief</option>
      <option value="PatientReport">Patientenbericht humangenetische Analyse</option>
      <option value="Other">Anderer</option>
    </select>

    <div class="d-flex flex-wrap mt-3">
      <div class="border rounded mt-3 me-3 p-2" v-for="file in state.files" :key="file">{{ file.name }}</div>
      <input ref="fileUpload" type="file" id="fileElem" accept="*" style="display:none" @change="state.files = fileUpload.files;">
      <button class="btn btn-secondary mt-3" @click="fileUpload.click()">Datei auswählen</button>
    </div>

    <hr/>

    <button class="btn btn-primary" :disabled="!(state.files && state.fileType)" type="button" id="button-addon2" @click="uploadFiles()">Dokument hinzufügen</button>
    <button class="btn btn-outline-secondary ms-2" type="button" id="button-addon2" @click="showUploadFile(false)">Verwerfen</button>

  </div>
</div>
</transition>

<hr/>

<!-- Health Records -->
<div class="my-4 row" v-for="hr in $props.patient.healthRecords" :key="hr">
    <HealthRecord :healthRecord="hr" @onUpdated="$emit('onUpdated')"/>
</div>

</template>

<script>

import { reactive, ref } from 'vue'

import api from '@/modules/api.js';
import HealthRecord from '@/views/patient/HealthRecord.vue';

export default {
  name: 'HealthRecordList',
  components: {
    HealthRecord,
  },
  props: {
    patient: Object
  },
  emits: [
    'onBusy', 'onUpdated',
  ],
  setup(props, { emit }) {

    const FORMS = { None: 0, NewNote: 1, FileUpload: 2 };

    const fileUpload = ref(null);

    const state = reactive({
      showForm: FORMS.None,

      // new text
      newNoteText: "",

      // file upload
      fileType: "",
      files: null,
      fileDescription: ""
    });

    function showNewNote(show) {
      state.showForm = show === true ? FORMS.NewNote : FORMS.None;
      state.newNoteText = "";
    }

    function saveNewNote() {

      emit('onBusy');

      api.addHealthRecord(props.patient.uuid, {
        type: "Note",
        subType: "Manual", 
        values: [
          { 
            name: "text",
            value: state.newNoteText,
          },
        ]
      }).then(response => { 
        emit('onUpdated', response);
        showNewNote(false);
      });
    }

    function showUploadFile(show) {
      state.showForm = show === true ? FORMS.FileUpload : FORMS.None;
      state.fileType = "";
      state.files = null;
    }

    function uploadFiles() {
      api.uploadHealthRecordFile(props.patient.uuid, {
          type: "Report",
          subType: state.fileType
        },
        fileUpload.value.files).then(response => { 
          emit('onUpdated', response);
          showUploadFile(false);
      });
      return true;
    }

    return {
      FORMS,
      fileUpload,
      state,
      showNewNote,
      saveNewNote,
      showUploadFile,
      uploadFiles,
    };
  }
};
</script>
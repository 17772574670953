<template>

<Toolbar :activeMenu="'Dashboard'"/>

<div class="w-100 overflow-auto">

<div class="header">

  <div class="container px-5 pt-5">

    <!-- Headline -->
    <h1 class="display-5">
      <i class="bi-house pe-2 text-primary" role="img" aria-label="Appointments"></i>
      Hallo, {{ state.user.name + ' ' + state.user.familyName }}
    </h1>
    <span class="lead">Hier finden Sie alle wichtigen Informationen auf einen Blick</span>

  </div>
</div>

<div class="container px-5 pb-5">

  <div v-if="$auth.hasRole('Doctor')" class="row mt-5">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Sprechstunden</h5>
          <p class="card-text">Tragen Sie Sprechstunden ein, so dass Patienten Termine bei Ihnen buchen können.</p>
          <a href="./schedule" class="btn btn-primary">Sprechstunden eintragen</a>
        </div>
      </div>
    </div>
  </div>

  <div v-if="$auth.hasRole('Admin')" class="row mt-5">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Info</h5>
          <p class="card-text">Aktuell sind noch keine Informationen für Administratoren verfügbar.</p>
        </div>
      </div>
    </div>
  </div>

</div>
</div>
</template>

<script>

import { reactive } from 'vue';
import auth from '@/modules/auth.js';

import Toolbar from '../components/Toolbar.vue'

export default {
  name: 'Dashboard',
  components: {
     Toolbar,
  },
  setup() {

    const state = reactive({
      user: {},
    });

    auth.getUser().then(response => { 
      state.user = response
    });

    return {
      state,
    };
  }
};
</script>
<template>

<!-- Patient -->
<div class="mt-5 border shadow p-3">
<div class="mb-2 d-flex align-items-center">
  <span class="fs-4 lead">Personendaten</span>
  <i class="bi-pencil btn btn-link" @click="edit(FORMS.Person)"></i>
</div>

<form>
<fieldset :disabled="state.edit != FORMS.Person">
<div class="row pt-2">

  <div class="col-lg-4 mb-4">
      <label for="gender" class="form-label">Anrede</label>
      <select v-model="$props.patient.gender" id="selectGender" class="form-select">
        <option value="Male" selected>Herr</option>
        <option value="Female">Frau</option>
        <option value="Diverse">Keine</option>
        </select>
  </div>

  <div class="col-lg-4 mb-4">
      <label for="inputName" class="form-label">Name</label>
      <input v-model="$props.patient.name" id="inputName" type="text" class="form-control" placeholder="Vorname">
  </div>

  <div class="col-lg-4 mb-4">
      <label for="inputFamilyName" class="form-label">Vorname</label>
      <input v-model="$props.patient.familyName" id="inputFamilyName" type="text" class="form-control" placeholder="Nachname">
  </div>

  <div class="col-lg-4 mb-4">
      <label for="inputBirthdate" class="form-label">Geburtsdatum</label>
      <input v-model="$props.patient.birthdate" id="inputBirthdate" type="date" class="form-control" >
  </div>

  <div v-if="state.edit == FORMS.Person" class="col-12 mb-4">
    <button type="button" class="btn btn-primary me-2" @click="save(FORMS.Person)">Speichern</button>
    <button type="button" class="btn btn-outline-secondary" @click="cancel()">Verwerfen</button>
  </div>

</div>
</fieldset>
</form>
</div>

<!-- Kontaktdaten -->
<div class="mt-5 border shadow p-3">
<div class="mb-2 mb-2 d-flex align-items-center">
  <span class="fs-4 lead">Kontaktdaten</span>
  <i class="bi-pencil btn btn-link" @click="edit(FORMS.Contact)"></i>
</div>

<form>
<fieldset :disabled="state.edit != FORMS.Contact">

<div class="row pt-2">

  <div class="col-12 mb-4">
      <label for="inputAddress" class="form-label">Adresse</label>
      <input v-model="$props.patient.address.fullAddress" type="text" id="inputAddress" class="form-control" placeholder="Format: Straße Hausnummer, PLZ Stadt">
  </div>

  <div class="col-4 mb-4">
      <label for="inputMobilenumber" class="form-label">Telefonnummer</label>
      <input v-model="$props.patient.address.mobileNumber" type="text" id="inputMobilenumber" class="form-control" placeholder="Mobile Telefonnummer">
  </div>

  <div class="col-4 mb-4">
      <label for="inputEmail" class="form-label">Email</label>
      <input v-model="$props.patient.address.email" type="text" id="inputEmail" class="form-control" placeholder="Email">
  </div>

  <div v-if="state.edit == FORMS.Contact" class="col-12 mb-4">
    <button type="button" class="btn btn-primary me-2" @click="save(FORMS.Contact)">Speichern</button>
    <button type="button" class="btn btn-outline-secondary" @click="cancel()">Verwerfen</button>
  </div>

</div>
</fieldset>
</form>
</div>

<!-- Insurance -->
<div class="my-5 border shadow p-3">
<div class="mb-2 mb-2 d-flex align-items-center">
  <span class="fs-4 lead">Versicherung</span>
  <i class="bi-pencil btn btn-link" @click="edit(FORMS.Insurance)"></i>
</div>

<form>
<fieldset :disabled="state.edit != FORMS.Insurance">

<div class="row pt-2">

  <div class="col-4 mb-4">
      <label for="inputInsurance" class="form-label">Versicherungsinstitut</label>
      <input v-model="$props.patient.insurance.instituteName" type="text" id="inputInsurance" class="form-control" placeholder="Krankenkassenname">
  </div>

  <div class="col-4 mb-4">
      <label for="inputInsuranceNumber" class="form-label">Institutskennzeichen</label>
      <input v-model="$props.patient.insurance.instituteIdentifier" type="text" id="inputInsuranceNumber" class="form-control" placeholder="Institutsnummer">
  </div>
</div>

<div class="row pt-2">

  <div class="col-4 mb-4">
      <label for="inputInsuranceNumber" class="form-label">Versicherungsnummer</label>
      <input v-model="$props.patient.insurance.insuranceNumber" type="text" id="inputInsuranceNumber" class="form-control" placeholder="Versicherungsnummer">
  </div>

  <div class="col-4 mb-4">
      <label for="inputValidTo" class="form-label">Gültig bis</label>
      <input v-model="$props.patient.insurance.insuranceValidTo" id="inputValidTo" type="date" class="form-control" >
  </div>

  <div v-if="state.edit == FORMS.Insurance" class="col-12 mb-4">
    <button type="button" class="btn btn-primary me-2" @click="save(FORMS.Insurance)">Speichern</button>
    <button type="button" class="btn btn-outline-secondary" @click="cancel()">Verwerfen</button>
  </div>

</div>
</fieldset>
</form>
</div>

</template>

<script>

import { reactive } from 'vue'

import api from '@/modules/api.js';

export default {
  name: 'PatientOverview',
  props: {
    patient: Object,
  },
  emits: [
    'onBusy',
    'onUpdated',
  ],
  setup(props, { emit }) {

    const FORMS = { None: 0, Person: 1, Contact: 2, Insurance: 3};

    const state = reactive({
      edit: FORMS.None,
    });

    function edit(form) {
      state.edit = form;      
    }

    function cancel() {
      state.edit = FORMS.None;
      emit('onUpdated');
    }

    function save(form) {

      // emit('onBusy');
      state.edit = FORMS.None;

      switch (form) {

        case FORMS.Person:

          api.updatePatient(props.patient.uuid, {
            gender: props.patient.gender,
            name: props.patient.name,
            familyName: props.patient.familyName,
            birthdate: props.patient.birthdate
          }).then(response => { emit('onUpdated', response); } );

          break;

        case FORMS.Contact:

          api.updatePatient(props.patient.uuid, {
            address: {
              fullAddress: props.patient.address.fullAddress,
              mobileNumber: props.patient.address.mobileNumber,
              email: props.patient.address.email
            }
          }).then(response => { emit('onUpdated', response); } );

          break;

        case FORMS.Insurance:

          api.updatePatient(props.patient.uuid, {
            insurance: {
              instituteName: props.patient.insurance.instituteName,
              instituteIdentifier: props.patient.insurance.instituteIdentifier,
              insuranceNumber: props.patient.insurance.insuranceNumber,
              insuranceValidTo: props.patient.insurance.insuranceValidTo
            }
          }).then(response => { emit('onUpdated', response); } );

          break;
      }
    }

    return {
      FORMS,
      state,
      edit,
      save,
      cancel,
    };
  }
};
</script>
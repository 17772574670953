<template>

<!-- Order testkit dialog -->
<div class="modal" ref="commissionDialog" tabindex="-1">
<div class="modal-dialog modal-m">
  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">Laborauftrag kommissionieren</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="modal-body">
      
      <div class="m-2 lead">
        {{ state.orders.length }} {{ state.orders.length == 1 ? "Auftrag" : "Aufträge" }} ausgewählt
      </div>

      <div class="p-2">
        <button class="btn btn-primary" style="width:100%" type="button" id="button-addon2"  @click="generateDHLOrderCSV()">
          DHL CSV Datei herunterladen
        </button>
      </div>

      <div class="p-2">
        <div class="input-group">
          <span class="input-group-text">Etiketten ab Zeile</span>
          <input v-model="state.startRow" type="text" class="form-control" placeholder="1" >
          <button class="btn btn-primary" type="button" id="button-addon2" @click="generateTestkitLabels()">
            Herunterladen
          </button>
        </div>
      </div>

    </div>

  </div>
</div>
</div>

</template>

<script>

import { reactive, ref } from 'vue'

import { Modal } from 'bootstrap'

import api from '@/modules/api.js';

export default {
  name: 'CommissionDialog',
  setup() {

    //
    // ref to order test kit dialog
    const commissionDialog = ref(null);
    var commissionModal;

    const state = reactive({

      orders: [],

      startRow: 1,
    });

    function show(orders) {
      state.orders = orders;
      commissionModal = new Modal(commissionDialog.value);
      commissionModal.show();
    }

    function generateDHLOrderCSV() {
      api.generateDHLOrderCSV(toOrderUUIDs());
    }

    function generateTestkitLabels() {
      api.generateTestkitLabels(state.startRow, toOrderUUIDs());
    }

    function toOrderUUIDs() {
      return state.orders.map((o) => o.uuid );
    }

    return {
      commissionDialog,
      state,
      show,
      generateTestkitLabels,
      generateDHLOrderCSV,
    };
  }
};
</script>

<template>

<!-- Order testkit dialog -->
<div class="modal" ref="trackingDialog" tabindex="-1">
<div class="modal-dialog modal-lg">
  <div v-if="state.order" class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">Sendungsverfolgung</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="modal-body">

      <!-- Sender & Receiver -->
      <div class="row">

        <div class="col-lg-6 mb-4">
          <div class="fs-4">
              <i class="bi-person-lines-fill fs-3 me-1"></i>
              {{ state.order.patient.name + ' ' + state.order.patient.familyName }}
          </div>
          <div class="lead">{{ state.order.patient.address.street }}</div>
          <div class="lead">{{ state.order.patient.address.zip + ' ' + state.order.patient.address.city }}</div>
        </div>

        <div class="col-lg-6 mb-4">
          <div class="fs-4">
            <i class="bi-building fs-3 me-1"></i>
            {{ state.order.createdBy.tenant.name }}
          </div>
          <div class="lead">{{ state.order.createdBy.tenant.street }}</div>
          <div class="lead">{{ state.order.createdBy.tenant.zip + ' ' + state.order.createdBy.tenant.city }}</div>
        </div>

      </div>

      <!-- Outbound / Inbound Tracking Numbers -->
      <div class="row">

        <div class="col-lg-6 mb-4">
            <label for="inputName" class="form-label">viagen <i class="bi-arrow-right"></i> Patient</label>
            <input v-model="state.order.outboundTrackingNumber" id="outboundTrackingNumber" type="text" class="form-control" placeholder="Tracking Nummer">
        </div>

        <div class="col-lg-6 mb-4">
            <label for="inputFamilyName" class="form-label">Patient <i class="bi-arrow-right"></i> Labor</label>
            <input v-model="state.order.inboundTrackingNumber" id="inboundTrackingNumber" type="text" class="form-control" placeholder="Tracking Nummer">
        </div>

      </div>

    </div>

    <div class="modal-footer">
        <button class="btn btn-primary" @click="save">Speichern</button>
    </div>

  </div>
</div>
</div>

</template>

<script>

import { reactive, ref } from 'vue'

import { Modal } from 'bootstrap'

import api from '@/modules/api.js';

export default {
  name: 'TrackingDialog',
  emits: [
    'onUpdated'
  ],
  setup(props, { emit }) {

    //
    // ref to order test kit dialog
    const trackingDialog = ref(null);
    var trackingModal;

    const state = reactive({
      order: null
    });

    function show(order) {
      state.order = order;
      trackingModal = new Modal(trackingDialog.value);
      trackingModal.show();
    }

    function save() {
      api.updateOrderTrackingNumbers(state.order.uuid, {
        "outboundTrackingNumber": state.order.outboundTrackingNumber,
        "inboundTrackingNumber": state.order.inboundTrackingNumber
      }).
      then(response => {
        emit('onUpdated', response);
        trackingModal.hide();
      });
    }

    return {
      trackingDialog,
      state,
      show,
      save,
    };
  }
};

</script>

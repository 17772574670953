import { createWebHistory, createRouter } from "vue-router";

import auth from '../modules/auth.js';

//
// login / users etc.
import Login from "@/views/Login.vue";

//
// dashboard
import Dashboard from "@/views/Dashboard.vue";

//
// appointment booking
import NewAppointment from "@/views/booking/NewAppointment.vue";
import Appointment from "@/views/booking/Appointment.vue";

//
// appointment(s)
import AppointmentList from "@/views/appointment/AppointmentList.vue";

//
// consultation
import ConsultationList from "@/views/consultation/ConsultationList.vue";
import Consultation from "@/views/consultation/Consultation.vue";
import ConsultationOverview from "@/views/consultation/ConsultationOverview.vue";
import ConsultationPatient from "@/views/consultation/ConsultationPatient.vue";
import ConsultationMedicalHistory from "@/views/consultation/ConsultationMedicalHistory.vue";

//
// scheduling
import Schedule from "@/views/schedule/Schedule.vue";

//
// orders
import OrderList from "@/views/order/OrderList.vue";
import OrderListView from "@/views/order/OrderListView.vue";

//
// settings
import Settings from "@/views/settings/Settings.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/bookAppointment",
    name: "New Appointment",
    component: NewAppointment,
  },
  {
    path: "/appointments/:uuid",
    name: "Appointment",
    component: Appointment,
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: "/consultations",
    name: "ConsultationList",
    component: ConsultationList,
    beforeEnter: requireAuth
  },
  {
    path: "/consultations/:consultationUuid",
    name: "Consultation",
    component: Consultation,
    children: [
      {
        path: '',
        component: ConsultationOverview,
      },
      {
        path: 'patient',
        component: ConsultationPatient,
      },
      {
        path: 'medicalHistory',
        component: ConsultationMedicalHistory,
      },
    ],
    beforeEnter: requireAuth,
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: Schedule,
    beforeEnter: requireAuth
  },
  {
    path: "/appointments",
    name: "AppointmentList",
    component: AppointmentList,
    beforeEnter: requireAuth
  },
  {
    path: "/orders",
    name: "OrderList",
    component: OrderList,
    children: [
      {
        path: 'commission',
        component: OrderListView,
        props: { view: 'Commission' }
      },
      {
        path: 'outbound',
        component: OrderListView,
        props: { view: 'Outbound' }
      },
      {
        path: 'inbound',
        component: OrderListView,
        props: { view: 'Inbound' }
      },
    ],
    beforeEnter: requireAuth
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: requireAuth
  },
  {
    path: "/*",
    component: 404
  },
];

function requireAuth (to, from, next) {
  auth.isAuthenticated().then(isAuthenticated => {
    if (isAuthenticated) {
      next()
    }
    else {
      next({
          path: '/login',
          query: { redirect: to.fullPath }
      })
    }
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const scrollBox = document.getElementById('scrollbox');
    console.log("Scrolled box to top:" + scrollBox);
    if (scrollBox) {
      scrollBox.scrollTop = 0;
    }
  },
});


export default router;
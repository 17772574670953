<template>

<div class="row">

  <div class="col-xl-6">
    <h1 class="display-6 fw-bold">Erbliche Diabetes</h1>
    <p class="fs-5">Bitte machen Sie folgenden Angaben zu Ihrer Krankengeschichte.*</p>
    <p class="text-muted">
      Mit Hilfe Ihrer Angaben können wir prüfen ob für Sie eine von der gesetzlichen Krankenkasse 
      übernommene humangenetische Beratung und Analyse auf vererbte Diabetes in Frage kommt.
      Wir übertragen Ihre Gesundheitsdaten erst, sobald Sie einen Termin gefunden, verbindlich 
      gebucht und explizit zugestimmt haben.</p>        
  </div>

  <div class="col-xl-6">

    <form class="needs-validation" :class="{ 'was-validated' : !state.medicalHistory.valid }" novalidate>
    <div class="row">

      <div class="col-6">
      <label for="inputAge" class="form-label">Geburtsdatum</label>
      <div class="input-group has-validation mb-4">
        <input v-model="state.medicalHistory.birthdate" type="date" class="form-control" id="inputBirthdate"  required>
        <div class="invalid-feedback">Bitte geben Sie ein gültiges Alter an.</div>
      </div>
      </div>

      <div class="col-6">
      <label for="inputAge" class="form-label">Alter Diagnose Diabetes</label>
      <div class="input-group has-validation mb-4">
        <input v-model="state.medicalHistory.ageDiagnosis" type="number" class="form-control" id="inputAge" placeholder="In welchem Alter wurde bei Ihnen die Diagnose Diabetes gestellt" required>
        <span class="input-group-text" id="basic-addon2">Jahre</span>          
        <div class="invalid-feedback">Bitte geben Sie ein gültiges Alter an.</div>
      </div>
      </div>

      <div class="col-6">
      <label for="inputHeight" class="form-label">Größe</label>
      <div class="input-group mb-4">
        <input v-model="state.medicalHistory.height" type="number" class="form-control" id="inputHeight" placeholder="Bitte geben Sie ihre Größe in cm ein" required>
        <span class="input-group-text" id="basic-addon2">cm</span>          
        <div class="invalid-feedback">Bitte geben Sie eine gültiges Größe an.</div>
      </div>
      </div>

      <div class="col-6">
      <label for="inputWeight" class="form-label">Gewicht</label>
      <div class="input-group mb-4">
        <input v-model="state.medicalHistory.weight" type="number" class="form-control" id="inputWeight" placeholder="Bitte geben Sie ihr Gewicht in kg ein" required>
        <span class="input-group-text" id="basic-addon2">kg</span>          
        <div class="invalid-feedback">Bitte geben Sie ein gültiges Gewicht an.</div>
      </div>
      </div>

      <div class="col">
      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-primary rounded" @click="next($event)">Weiter zu Termin</button>
      </div>
      </div>

    </div>
    </form>

  </div>
</div>
</template>

<script>

import { computed, reactive } from 'vue'

import log from '@/modules/logging.js';

export default {
  name: 'QuestionaireDiabetes',
  emits: [ 'onNext', 'onNotEligible' ],
  setup(props, { emit }) {

    const state = reactive({

      /**
       * diabetes questionaire
       */
      medicalHistory: {
        valid: true,
        birthdate: null,
        ageDiagnosis: null,
        weight: null,
        height: null,
        bmi: computed(() => {
          return state.medicalHistory.weight / Math.pow(state.medicalHistory.height / 100.0, 2);
        })
      },
    });

    function next(event) {

      //
      // prevent default behaviour
      // TODO: check if this can be done via a "vue modifier"
      if (event != null) {
        event.preventDefault();
        event.stopPropagation();
      }

      //
      // validate medical history
      if (!isValid()) {
        return false;
      }

      //
      // check, if eligible
      if (isEligible()) {
        emit('onNext', toQuestionaire());
      }
      else {
        emit('onNotEligible');
      }
    }

    function isValid() {

      //
      // check required fields and ranges first
      state.medicalHistory.valid = 
        (state.medicalHistory.birthdate) && // need additional checks (age > 18 and age < age diagnosis)
        (state.medicalHistory.ageDiagnosis != null && state.medicalHistory.ageDiagnosis > 6 && state.medicalHistory.ageDiagnosis < 99) &&
        (state.medicalHistory.weight != null && state.medicalHistory.weight > 35 && state.medicalHistory.weight < 250) &&
        (state.medicalHistory.height != null && state.medicalHistory.height > 130 && state.medicalHistory.height < 220);

      log.trace("QuestionaireDiabetes:isValid() Diabetes/Medical History valid: {}", state.medicalHistory.valid);

      return state.medicalHistory.valid;
    }

    function isEligible() {
      return state.medicalHistory.valid && 
        state.medicalHistory.ageDiagnosis <= 30 && 
        state.medicalHistory.bmi <= 30;
    }

    function toQuestionaire() {

        return [{
          type: "Questionaire",
          subType: "Diabetes", 
          values: [
            { 
              name: "birthdate",
              value: state.medicalHistory.birthdate,
              unit: "Date"
            },
            { 
              name: "weight",
              value: state.medicalHistory.weight,
              unit: "kg"
            },
            { 
              name: "height",
              value: state.medicalHistory.height,
              unit: "cm"
            },
            { 
              name: "ageDiagnosis",
              value: state.medicalHistory.ageDiagnosis,
              unit: "year"
            },
            { 
              name: "bmi",
              value: state.medicalHistory.bmi,
              unit: "BMI"
            },
          ]
        }];
    }

    return {
      state,
      next,
    };
  }
};
</script>

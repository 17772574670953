<template>
<div>

<OrderTestKitDialog ref="orderTestKitDialog" :consultation="state.consultation" @onTestKitOrdered="refresh()"></OrderTestKitDialog>

<!-- Finished successfully -->
<div v-if="isConsultationFinishedSuccessfully()" class="mt-5 display-5">
  <i class="bi bi-check-circle-fill text-primary"></i> Beratung erfolgreich abgeschlossen
</div>

<!-- Finished without indication -->
<div v-if="isConsultationFinishedWithoutIndication()" class="mt-5 display-5">
  <i class="bi bi-stop-circle-fill text-warning"></i> Indikation ist nicht gegeben
</div>

<!-- Cancelled -->
<div v-if="isConsultationCancelled()" class="mt-5 display-5">
  <i class="bi bi-x-circle-fill text-danger"></i> Beratung abgebrochen
  <div class="lead mt-2">Patient hat Gespräch nicht wahrgenommen.</div>
</div>

<!-- Consultation Stuck -->
<div v-if="isConsultationStoppedByProblem()" class="mt-5 display-5">
  <i class="bi bi-x-circle-fill text-warning"></i> Es ist ein Problem aufgetreten
  <div class="lead mt-2">{{ state.consultation.detailedStatus.message }}</div>
</div>

<!-- Patient Report Status -->
<div v-if="state.consultation.order && state.consultation.order.inboundStatus == 'Delivered' && !state.consultation.reportHealthRecord" class="bg-light mt-5 p-4 rounded">

  <div class="display-6">Befundung</div>

  <!-- Lab Report Status -->
  <div v-if="state.consultation.order.reportHealthRecord" class="lead mt-4">
    Laborbericht ist verfügbar und kann <a href="#">heruntergeladen</a> werden.
  </div>
  <div v-if="!state.consultation.order.reportHealthRecord" class="lead mt-4">
    Patietenprobe ist im Labor eingegangen. Aktuell ist der Laborbericht aber noch nicht über die Platform verfügbar. Sobald Sie den Laborbefund bekommen und ausgewertet haben, können Sie den Bericht für den Patienten hier hochladen.
  </div>

  <!-- lab result is available -->
  <input ref="fileUpload" type="file" id="fileElem" multiple accept="*" style="display:none" @change="uploadFiles()">
  <button class="btn btn-primary mt-4" @click="fileUpload.click()">
    Patientenbericht hochladen
  </button>

</div>

<!-- Show Order Status -->
<div v-if="state.consultation.order && state.consultation.order.inboundStatus != 'Delivered'" class="bg-light mt-5 p-4 rounded">

  <div class="display-6">Laborauftrag</div>

  <!-- status of order -->
  <div v-if="!state.consultation.order.reportHealthRecord" class="lead mt-4">
    {{ state.consultation.detailedStatus.message }}
  </div>

</div>

<!-- Ask for Indication -->
<div v-if="askForIndication()" class="bg-light mt-5 p-4 rounded">

  <div class="display-6">Indikation stellen</div>
  <div class="lead my-4">
    Bitte wählen Sie, ob eine Indikation gegeben ist und eine Entnahmesystem gesendet werden soll. 
    Bitte geben Sie auch an, wenn keine Indikation gegeben ist oder der Patient die humangenetische Analyse nicht durchführen möchte und der Beratungsfall geschlossen werden soll.
  </div>
  <a class="btn btn-primary me-2 mt-2" ref="orderTestKitButton" href="#" @click="showOrderTestKit()" role="button" data-bs-toggle="popover" data-bs-trigger="focus" title="Hinweis" data-bs-content="Bitte tragen Sie unter Personendaten die Adresse des Patienten ein.">
    <i class="bi-box-seam me-2" role="img"></i>
    Entnahmesystem zusenden
  </a>
  <a class="btn btn-secondary me-2 mt-2" href="#" @click="setNoIndication()">
    <i class="bi-slash-circle me-2" role="img"></i>
    Keine Indikation gegeben
  </a>

</div>

<!-- Appointment: Waiting for Dial-In -->
<div v-if="waitingForDialIn(state.consultation.appointment)" class="row bg-light mt-5 p-4 rounded">

  <div class="col-8 lead">

    <div class="display-6">Beratungstermin</div>

    <div class="mt-4">{{ state.consultation.patient.fullName }} hat einen Beratungstermin mit Ihnen vereinbart. Falls sich der Patient auch 5min nach dem vereinbarten Termin noch nicht einwählt hat, dann versuchen Sie bitte den Patienten unter der angegebenden Telefonnummer zu erreichen.</div>

    <div class="mt-4">
      <i class="bi-calendar-check me-2"></i>
      {{ $fmt.fromMillisToDateTime(state.consultation.appointment.slotAt) }}
    </div>
    <div class="mt-3">
      <i class="bi-telephone me-2"></i>
      {{ state.consultation.patient.address.mobileNumber }}
    </div>
    <div class="mt-3">
      <i class="bi-envelope me-2"></i>
      {{ state.consultation.patient.address.email }}
    </div>

  </div>

  <!-- Show current status of call  (waiting, calling, etc...) -->
  <div class="col-4">
    <CallStatus class="shadow h-100" :appointment="state.consultation.appointment" @onUpdate="refresh()"/>
  </div>

</div>

<!-- Show Instructions / Reminder -->
<div v-if="showInstructions(state.consultation.appointment)" class="row bg-light mt-5 p-4 rounded">

  <div class="col-12 lead">

    <div class="display-6">Bitte nicht vergessen...</div>

    <div class="mt-4">Bitte vergessen Sie während des Beratungsgespräch nicht, die folgenden Daten beim Patienten nachzufragen. Vielen Dank. </div>

    <div class="mt-4">
      <i class="bi-shield-shaded me-2 text-primary"></i>
      Bitte erfassen Sie die Versicherung des Patienten hier im System unter dem Reiter Personendaten oder in Ihrem Praxisinformationssystem
    </div>
    <div class="mt-3">
      <i class="bi-geo-alt me-2 text-primary"></i>
      Falls eine Indikation gegeben ist erfassen Sie bitte auch die Adresse hier im System unter dem Reiter Personendaten, damit das Entnahmesystem versendet werden kann.
    </div>
    <div class="mt-3">
      <i class="bi-check-circle me-2 text-primary"></i>
      Falls eine Indikation gegeben ist, klären Sie den Patienten bitte auch explizit nach den Vorgaben des GenDG auf.
    </div>

    <button class="btn btn-primary mt-3" @click="setInstructionsRead(state.consultation.appointment)">
      <i class="bi bi-check"></i>
      Alles klar, verstanden!
    </button>

  </div>

</div>

<!--
  History / Activity 
-->
<div class="fs-4 mt-5"> 
  Verlauf
  <hr/>
</div>

<!-- Consultation Report for Patient (Uploaded by Professional) -->
<div v-if="state.consultation.reportHealthRecord" class="row mt-4">
  <HealthRecord :healthRecord="state.consultation.reportHealthRecord">
    <div class="fs-5 mb-1">
      <i class="bi-file-medical me-2"></i>Patientenbefund
    </div>
  </HealthRecord>
</div>

<!-- Order -->
<div v-if="state.consultation.order && state.consultation.order.inboundStatus == 'Delivered'" class="row mt-4">

  <!-- Caption -->
  <div class="history-caption">
      <div class="fs-5 mb-4">
        <i class="bi-box-seam me-2"></i>Laborauftrag
        <div class="mt-1" style="font-size:0.9rem;font-weight:600">{{ $fmt.fromMillisToDateTime(state.consultation.order.inboundStatusChangedAt) }}</div>
      </div>
  </div>

  <!-- Details -->
  <div class="history-content">

    <div class="row">
      <div class="col-12">
        <span class="lead">{{ state.consultation.order.detailedStatus.message }}</span>
        <div style="font-size:0.9rem;font-weight:600">Status</div>
      </div>
    </div>

    <div v-if="state.consultation.order.reportHealthRecord" class="row">
      <div class="col-12">
        <a class="lead" href="#" @click="downloadHealthRecordFile(state.consultation.order.reportHealthRecord)">
          Laborbefund
        </a>
        <div style="font-size:0.9rem;font-weight:600">Befund</div>
      </div>
    </div>

  </div>

</div>

<!-- No Indication Health Record -->
<div v-if="state.consultation.noIndicationHealthRecord" class="row mt-4">
  <HealthRecord :healthRecord="state.consultation.noIndicationHealthRecord">
    <div class="fs-5 mb-1">
      <i class="bi-file-medical me-2"></i>Keine Indikation
    </div>
  </HealthRecord>
</div>

<!-- Conducted Appointment -->
<div v-if="!waitingForDialIn(state.consultation.appointment)" class="row mt-4">

  <!-- Caption -->
  <div class="history-caption">
      <div class="fs-5 mb-4">
        <i class="bi-clipboard-check me-2"></i>Beratungstermin
        <div class="mt-1" style="font-size:0.9rem;font-weight:600">{{ $fmt.fromMillisToDateTime(state.consultation.appointment.slotAt) }}</div>
      </div>
  </div>

  <!-- Details -->
  <div class="history-content">
    <div class="row">
      <div class="col-8">
        <span class="lead">{{ state.consultation.appointment.detailedStatus.message }}</span>
        <div style="font-size:0.9rem;font-weight:600">Status</div>
      </div>
      <div class="col-4">
      </div>
    </div>
  </div>

</div>

<!-- Self anamnese -->
<div v-if="state.consultation.patientReportedHealthRecord" class="row mt-4">
  <HealthRecord :healthRecord="state.consultation.patientReportedHealthRecord" @onUpdated="refresh()">
    <div class="fs-5 mb-1">
      <i class="bi-file-medical me-2"></i>Eigenanamnese
    </div>
  </HealthRecord>
</div>

</div>
</template>

<script>


import { reactive, watch, ref } from 'vue'
import { useRoute } from 'vue-router'

import { Popover } from 'bootstrap'

import CallStatus from '@/views/consultation/CallStatus.vue'

import OrderTestKitDialog from '@/views/patient/OrderTestKitDialog.vue';
import HealthRecord from '@/views/patient/HealthRecord.vue';

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'ConsultationOverview',
  emits: [ 'onUpdated', 'onBusy' ],
  components: {
    OrderTestKitDialog,
    HealthRecord,
    CallStatus,
  },
  setup() {

    const fileUpload = ref();

    const orderTestKitDialog = ref();

    const orderTestKitButton = ref();

    var orderTestKitButtonPopover = null;

    //
    // keep track of route
    const route = useRoute();
    watch(() => route.path, () => {
      resolveRoute();
    });

    const state = reactive({

      // the current consultation
      consultation: {
        currentStatus: {
          message: "",
        },
        appointment: {
          slotAt: 0,
          detailedStatus: {
            message: "",
          }
        },
        patient: {
          fullName: "",
          birthDate: 0,
          address: { },
          insurance: { }
        },
      }
    });

    function resolveRoute() {
      const path = route.path.split("/").filter(s => s.length > 0);
      if (path[0] == 'consultations' && path[1]) {
        query(path[1]);
      }
    }

    function refresh() {
      query(state.consultation.uuid);
    }

    function query(uuid) {
      api.getConsultation(uuid).then(response => {
        postQuery(response);
      });
    }

    function postQuery(response) {
      log.trace("ConsultationOverview:postQuery() Processing: {}", response);
      state.consultation = response;
    }

    function showOrderTestKit() {

      if (state.consultation.patient.addressComplete) {
        if (orderTestKitButtonPopover) {
          orderTestKitButtonPopover.hide();
        }
        orderTestKitDialog.value.show();
      }
      else {
        if (!orderTestKitButtonPopover) {
          orderTestKitButtonPopover = new Popover(orderTestKitButton.value);
        }
        orderTestKitButtonPopover.show();
      }
    }

    function setNoIndication() {
      api.setNoIndication(state.consultation.uuid, null).then(response => postQuery(response));
    }

    function setInstructionsRead(appointment) {
      api.setInstructionsRead(appointment.uuid).then(() => refresh());
    }

    function lookupValueByName(values, name) {
      for (let i = 0; i < values.length; i++) {
         if (values[i].name === name) {
           return values[i].value;
         }
      }
      return null;
    }

    function uploadFiles() {
      api.uploadPatientReport(state.consultation.uuid, {
          type: "Report",
          subType: "PatientReport"
        },
        fileUpload.value.files).then(response => { 
          postQuery(response);
      });
    }

    function isConsultationFinishedSuccessfully() {
      return state.consultation.reportHealthRecord;
    }

    function isConsultationCancelled() {
      return state.consultation.appointment.status == 'Cancelled';
    }

    /**
     * Problems shown here, can be somehow solved
     * 
     * TODO: We might need to add other edge cases here (overdue inbound shipping)
     * 
     */
    function isConsultationStoppedByProblem() {
      return state.consultation.appointment.status == 'CancelledByProfessional' ||
        (state.consultation.order && 
         (state.consultation.order.outboundStatus == 'Failure' ||
          state.consultation.order.inboundStatus == 'Failure'));
    }

    function isConsultationFinishedWithoutIndication() {
      return state.consultation.noIndicationHealthRecord != null;
    }

    function askForIndication() {
      return state.consultation.appointment.status === 'CallEstablished' && 
              state.consultation.appointment.readInstructionsAt &&
              !state.consultation.order &&
              !state.consultation.noIndicationHealthRecord;
    }

    function waitingForDialIn(appointment) {
      return (appointment.status == 'Pending' || appointment.status == 'CallStarted');
    }

    function showInstructions(appointment) {
      return appointment.status == 'CallEstablished' && !appointment.readInstructionsAt;
    }

    function downloadHealthRecordFile(healthRecord) {
      api.downloadHealthRecordFile(
        healthRecord.uuid, 
        lookupValueByName(healthRecord.values, 'internalFileKey'), 
        lookupValueByName(healthRecord.values, 'originalFileName'));
    }

    resolveRoute();

    return {
      fileUpload,
      orderTestKitDialog,
      orderTestKitButton,
      state,
      refresh,
      showOrderTestKit,
      waitingForDialIn,
      showInstructions,
      askForIndication,
      isConsultationFinishedSuccessfully,
      isConsultationFinishedWithoutIndication,
      isConsultationCancelled,
      isConsultationStoppedByProblem,
      downloadHealthRecordFile,
      setNoIndication,
      uploadFiles,
      setInstructionsRead
    };
  }
};
</script>
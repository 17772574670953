<template>

<!-- Medical History -->
<div class="row">

  <div class="col-xl-6">

    <h1 class="display-6 fw-bold">Erblicher Brust- und Eierstockkrebs</h1>
    <p class="fs-5">Bitte machen Sie folgenden Angaben zu Ihrer Krankengeschichte.</p>
    <p class="text-muted">
      Mit Hilfe Ihrer Angaben prüfen wir, ob wir Ihnen einen passenden humangenetischen Facharzt aus unserem Netzwerk vermitteln können.
    </p>
  </div>

  <div class="col-xl-6">

    <form class="needs-validation" :class="{ 'was-validated' : state.medicalHistory.valid != null }" novalidate>

      <label for="inputAge" class="form-label">Bitte nennen Sie uns Ihr Geburtsdatum</label>
      <DatePicker :pickedDate="state.medicalHistory.birthdate" @onDatePicked="setBirthdate($event)"></DatePicker>

      <!--
      <div class="col-md-5">
        <div class="input-group has-validation">
          <input v-model="state.medicalHistory.birthdate" type="date" class="form-control" id="inputBirthdate" required>
          <div class="invalid-feedback">Bitte geben Sie ein gültiges Alter an.</div>
        </div>
      </div>
      -->

      <div class="form-label mt-4">Wurde bei Ihnen Brustkrebs oder Eierstockkrebs diagnostiziert?</div>
      <div class="form-check">
        <input class="form-check-input" v-model="state.medicalHistory.cancerDiagnosis" type="radio" name="cancerDiagnosis" id="cancerDiagnosis_true" value="true" required>
        <label class="form-check-label" for="cancerDiagnosis_true">Ja</label>
      </div>
      <div class="col-4 form-check mt-2">
        <input class="form-check-input" v-model="state.medicalHistory.cancerDiagnosis" type="radio" name="cancerDiagnosis" id="cancerDiagnosis_false" value="false" required>
        <label class="form-check-label" for="cancerDiagnosis_false">Nein</label>
        <div class="invalid-feedback">Bitte wählen Sie eine Option aus.</div>
      </div>

      <div class="form-label mt-4">Hatten Sie vor- oder nach Ihrer Brust- bzw. Eierstockkrebserkrankung eine davon unabhängige Krebserkrankung (z.B. Hautkrebs)?</div>
      <div class="form-check">
        <input class="form-check-input" v-model="state.medicalHistory.otherCancerDiagnosis" type="radio" name="otherCancerDiagnosis" id="otherCancerDiagnosis_true" value="true" required>
        <label class="form-check-label" for="otherCancerDiagnosis_true">Ja</label>
      </div>
      <div class="col-4 form-check mt-2">
        <input class="form-check-input" v-model="state.medicalHistory.otherCancerDiagnosis" type="radio" name="otherCancerDiagnosis" id="otherCancerDiagnosis_false" value="false" required>
        <label class="form-check-label" for="otherCancerDiagnosis_false">Nein</label>
        <div class="invalid-feedback">Bitte wählen Sie eine Option aus.</div>
      </div>

      <div class="form-label mt-4">Wurde bei Ihnen die Diagnose Brustkrebs vor dem 51. Geburtstag gestellt?</div>
      <div class="form-check">
        <input class="form-check-input" v-model="state.medicalHistory.ageDiagnosisBefore51" type="radio" name="ageDiagnosisBefore51" id="ageDiagnosisBefore51_true" value="true" required>
        <label class="form-check-label" for="ageDiagnosisBefore51_true">Ja</label>
      </div>
      <div class="form-check mt-2">
        <input class="form-check-input" v-model="state.medicalHistory.ageDiagnosisBefore51" type="radio" name="ageDiagnosisBefore51" id="ageDiagnosisBefore51_false" value="false" required>
        <label class="form-check-label" for="ageDiagnosisBefore51_false">Nein</label>
        <div class="invalid-feedback">Bitte wählen Sie eine Option aus.</div>
      </div>

      <div class="form-label mt-4">Sind Ihnen in Ihrer Blutsverwandtschaft Personen bekannt, bei denen Krebs diagnostiziert wurde?</div>
      <div class="form-check">
        <input class="form-check-input" v-model="state.medicalHistory.cancerInFamiliy" type="radio" name="cancerInFamiliy" id="cancerInFamiliy_true" value="true" required>
        <label class="form-check-label" for="cancerInFamiliy_true">Ja</label>
      </div>
      <div class="form-check mt-2">
        <input class="form-check-input" v-model="state.medicalHistory.cancerInFamiliy" type="radio" name="cancerInFamiliy" id="cancerInFamiliy_false" value="false" required>
        <label class="form-check-label" for="cancerInFamiliy_false">Nein</label>
        <div class="invalid-feedback">Bitte wählen Sie eine Option aus.</div>
      </div>

      <div class="form-label mt-4">Wurde bei Ihnen ein sogenannter triple-negativer Brustkrebs festgestellt?</div>
      <div class="form-check">
        <input class="form-check-input" v-model="state.medicalHistory.tripleNegativeCancer" type="radio" name="tripleNegativeCancer" id="tripleNegativeCancer_true" value="true" required>
        <label class="form-check-label" for="tripleNegativeCancer_true">Ja</label>
      </div>
      <div class="form-check mt-2">
        <input class="form-check-input" v-model="state.medicalHistory.tripleNegativeCancer" type="radio" name="tripleNegativeCancer" id="tripleNegativeCancer_false" value="false" required>
        <label class="form-check-label" for="tripleNegativeCancer_false">Nein</label>
        <div class="invalid-feedback">Bitte wählen Sie eine Option aus.</div>
      </div>

      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-primary rounded" @click="next($event)">Weiter zu Termin</button>
      </div>

    </form>
  
  </div>
</div>

</template>

<script>

import { reactive, inject } from 'vue'

import DatePicker from './DatePicker.vue';

import log from '@/modules/logging.js';

export default {
  name: 'QuestionaireCancer',
  components: {
    DatePicker
  },
  emits: [ 'onNext', 'onNotEligible' ],
  props: {
    medicalHistory: Object,
  },
  setup(props, { emit }) {

    const tracking = inject("tracking");

    const state = reactive({

      /**
       * diabetes questionaire
       */
      medicalHistory: {
        valid: null,
        birthdate: null,
        cancerDiagnosis: null,
        otherCancerDiagnosis: null,
        ageDiagnosisBefore51: null,
        cancerInFamiliy: null,
        tripleNegativeCancer: null
      },
    });

    function next(event) {

      //
      // prevent default behaviour
      // TODO: check if this can be done via a "vue modifier"
      if (event != null) {
        event.preventDefault();
        event.stopPropagation();
      }

      //
      // validate medical history
      if (!isValid()) {
        tracking.trackEvent("BookAppointment", "Questionnaire: Invalid form submitted");
        return false;
      }

      //
      // check, if eligible
      if (isEligible()) {
        emit('onNext', toQuestionaire());
      }
      else {
        emit('onNotEligible');
      }
    }

    function isValid() {

      //
      // check required fields and ranges first
      state.medicalHistory.valid = 
        state.medicalHistory.birthdate != null && 
        state.medicalHistory.cancerDiagnosis != null && 
        state.medicalHistory.otherCancerDiagnosis != null && 
        state.medicalHistory.ageDiagnosisBefore51 != null && 
        state.medicalHistory.cancerInFamiliy != null && 
        state.medicalHistory.tripleNegativeCancer != null;

      return state.medicalHistory.valid;
    }

    function isEligible() {
      return state.medicalHistory.valid && 
        state.medicalHistory.cancerDiagnosis == "true" && (
        state.medicalHistory.otherCancerDiagnosis == "true" ||
        state.medicalHistory.ageDiagnosisBefore51 == "true" ||
        state.medicalHistory.cancerInFamiliy == "true" ||  
        state.medicalHistory.tripleNegativeCancer == "true");
    }

    function toQuestionaire() {
        return [{
          type: "Questionaire",
          subType: "Cancer",
          values: [
            { 
              name: "birthdate",
              value: state.medicalHistory.birthdate,
              unit: "Date"
            },
            { 
              name: "cancerDiagnosis",
              value: state.medicalHistory.cancerDiagnosis,
              unit: "Boolean"
            },
            { 
              name: "otherCancerDiagnosis",
              value: state.medicalHistory.otherCancerDiagnosis,
              unit: "Boolean"
            },
            { 
              name: "ageDiagnosisBefore51",
              value: state.medicalHistory.ageDiagnosisBefore51,
              unit: "Boolean"
            },
            { 
              name: "cancerInFamiliy",
              value: state.medicalHistory.cancerInFamiliy,
              unit: "Boolean"
            },
            { 
              name: "tripleNegativeCancer",
              value: state.medicalHistory.tripleNegativeCancer,
              unit: "Boolean"
            },
          ]
        }];
    }

    function toMedicalHistory() {

      log.trace("toMedicalHistory Source: {}", props.medicalHistory);

      if (props.medicalHistory && props.medicalHistory.length == 1) {

        state.medicalHistory.birthdate = 
          props.medicalHistory[0].values.find(v => v.name === "birthdate").value;
        state.medicalHistory.cancerDiagnosis = 
          props.medicalHistory[0].values.find(v => v.name === "cancerDiagnosis").value;
        state.medicalHistory.otherCancerDiagnosis = 
          props.medicalHistory[0].values.find(v => v.name === "otherCancerDiagnosis").value;
        state.medicalHistory.ageDiagnosisBefore51 = 
          props.medicalHistory[0].values.find(v => v.name === "ageDiagnosisBefore51").value;
        state.medicalHistory.cancerInFamiliy = 
          props.medicalHistory[0].values.find(v => v.name === "cancerInFamiliy").value;
        state.medicalHistory.tripleNegativeCancer = 
          props.medicalHistory[0].values.find(v => v.name === "tripleNegativeCancer").value;

        log.trace("toMedicalHistory Result: {}", state.medicalHistory);
      }
    }

    function setBirthdate(date) {
      state.medicalHistory.birthdate = date;
      log.trace("QuestionaireCancer:setBirthdate() State: {}", state);
    }

    toMedicalHistory();

    return {
      state,
      setBirthdate,
      next,
    };
  }
};
</script>
<template>

<main>

  <router-view />

</main>

</template>

<script>

import { reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import auth from './modules/auth.js';

export default {
  name: 'App',
  setup() {

    const state = reactive({
      loggedIn: false,
    });

    auth.isAuthenticated().then(isAuthenticated => { 
      state.loggedIn = isAuthenticated
    });

    const route = useRoute();

    watch(() => route.path, () => { 
      auth.isAuthenticated().then(isAuthenticated => { 
        state.loggedIn = isAuthenticated
      })
    });

    function logout() {
      auth.signOut().then(() => state.loggedIn = false);
    }

    return {
      state,
      logout,
    };
  }
}
</script>

<style lang="scss">

@import '@/assets/styles/custom-bootstrap.scss';

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

</style>

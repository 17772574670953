<template>

<div class="border shadow">
<slot></slot>
<table class="table table-hover rounded">

  <thead class="table-light">
    <tr>
      <th v-if="$props.withSender" width="25%" scope="col">Patient</th>
      <th width="25%" scope="col">Labor</th>
      <th width="15%" scope="col">Auftrag</th>
      <th width="35%" scope="col">Sendestatus</th>
      <th v-if="$props.withActions"></th>
    </tr>
  </thead>

  <tbody>
    <tr v-for="order in $props.orders" :key="order">

      <td v-if="$props.withSender" scope="row">
        <div class="fw-bold">{{ order.patient.name + ' ' + order.patient.familyName }}</div>
        <div>{{ order.patient.address.street }}</div>
        <div>{{ order.patient.address.zip + ' ' + order.patient.address.city }}</div>
      </td>

      <td>
        <div class="fw-bold">{{ order.createdBy.tenant.name }}</div>
        <div>{{ order.createdBy.tenant.street }}</div>
        <div>{{ order.createdBy.tenant.zip + ' ' + order.createdBy.tenant.city }}</div>
      </td>

      <td>
        <div class="fw-bold">{{ order.panel }}</div>
        <div>Panel</div>
      </td>

      <td>
        <div class="fw-bold">{{ orderUtils.mapStatusToMessage(order) }}</div>
        <div  v-if="order.outboundTrackingNumber || order.inboundTrackingNumber">
          <div>
            <i class="bi-box-arrow-right me-2" title="Tracking zum Patienten"></i>{{ order.outboundTrackingNumber ? order.outboundTrackingNumber : '-'}}
          </div>
          <div>
            <i class="bi-box-arrow-left me-2" title="Tracking ins Labor"></i>{{ order.inboundTrackingNumber ? order.inboundTrackingNumber : '-'}}
          </div>
        </div>
      </td>

      <!-- Actions -->
      <td v-if="$props.withActions">

        <div v-if="order.inboundStatus != 'Delivered'" class="dropdown text-end">
          <button class="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Aktionen
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
            <li v-if="orderUtils.isOrderReadyForCommission(order)">
              <a class="dropdown-item" href="#" @click="$emit('onCommission', [ order ])">
                <i class="bi-printer me-2"></i>Versanddatei erstellen &amp; Etiketten drucken
              </a>
            </li>
            <li v-if="orderUtils.isOrderReadyForCommission(order)">
              <a class="dropdown-item" href="#" @click="$emit('onTracking', order)">
                <i class="bi-geo-alt me-2"></i>Trackingdaten hinterlegen
              </a>
            </li>
            <li v-if="orderUtils.isOrderInShippingToPatient(order) && order.outboundStatus != 'Delivered'">
              <a class="dropdown-item" href="#" @click="setOutboundShipped(order)">
                <i class="bi-box-arrow-in-right me-2"></i>An Patient versendet
              </a>
            </li>
            <li v-if="orderUtils.isOrderInShippingToPatient(order) || orderUtils.isOrderInShippingToLab(order)">
              <a class="dropdown-item" href="#" @click="setInboundShipped(order)">
                <i class="bi-box-arrow-in-left me-2"></i>An Labor versendet
                </a>
              </li>
          </ul>
        </div>

    </td>

    </tr>
  </tbody>
</table>
</div>

</template>

<script>

import api from '@/modules/api';
import orderUtils from './OrderUtils';

export default {
  name: 'OrderTable',
  emits: [
    'onTracking',
    'onCommission',
    'onUpdated',    
  ],
  props: {
    orders: Array,
    withActions: {
      type: Boolean,
      default: true
    },
    withSender: {
      type: Boolean,
      default: true
    },
  },
  components: {
  },
  setup(props, { emit }) {

    function setOutboundShipped(order) {
      api.updateOrderShippingStatus(order.uuid, "Outbound", "Delivered").then(emit('onUpdated'));
    }

    function setInboundShipped(order) {
      api.updateOrderShippingStatus(order.uuid, "Inbound", "Delivered").then(emit('onUpdated'));
    }

    return {
      setOutboundShipped,
      setInboundShipped,
      orderUtils,
    };
  }
}
</script>

<template>

<!-- Info Column(s) -->
<div class="col-3 p-3">
  <slot></slot>
  <div style="font-size:0.9rem;font-weight:600">{{ $fmt.fromMillisToDateTime($props.healthRecord.createdAt) }}</div>
  <div style="font-size:0.9rem;font-weight:600" class="mt-3">
    Erfasst von {{ ($props.healthRecord.createdBy ? $props.healthRecord.createdBy.fullName : 'Patient') }}
    <i v-if="$props.healthRecord.reviewedBy" class="bi-check ms-1"></i>
  </div>
  <button v-if="!$props.healthRecord.createdBy && !$props.healthRecord.reviewedBy" @click="onReviewed()" class="btn btn-primary mt-4">
    Begutachtet
    <i class="bi-check text-primary"></i>
  </button>
</div>

<!-- Health Record Details Columns -->
<div class="col-9 bg-light border-3 border-start border-primary p-3">

  <div class="fs-5 mb-3 lead">{{ $props.healthRecord.type + ": " + $props.healthRecord.subType }}</div>

  <!-- Questionnaire -->
  <div v-if="$props.healthRecord.type == 'Questionaire'" class="row">

    <!-- Cancer questionnaire -->
    <div v-if="$props.healthRecord.subType == 'Cancer'">
      <div>
        <i class="bi me-2" :class="getQuestionClasses($props.healthRecord.values, 'cancerDiagnosis')"></i>
        Wurde bei Ihnen Brustkrebs oder Eierstockkrebs diagnostiziert?
      </div>
      <div>
        <i class="bi me-2" :class="getQuestionClasses($props.healthRecord.values, 'otherCancerDiagnosis')"></i>
        Hatten Sie vor- oder nach Ihrer Brust- bzw. Eierstockkrebserkrankung eine davon unabhängige Krebserkrankung (z.B. Hautkrebs)?
      </div>
      <div class="mt-2">
        <i class="bi me-2" :class="getQuestionClasses($props.healthRecord.values, 'ageDiagnosisBefore51')"></i>
        Wurde bei Ihnen Ihnen die Diagnose Brustkrebs oder Eierstockkrebs gestellt, bevor sie das 51. Lebensjahr erreicht haben?
      </div>
      <div class="mt-2">
        <i class="bi me-2" :class="getQuestionClasses($props.healthRecord.values, 'cancerInFamiliy')"></i>
        Sind Ihnen in Ihrer linearen Verwandtschaft Personen bekannt, bei denen Krebs diagnostiziert wurde?
      </div>
      <div class="mt-2">
        <i class="bi me-2" :class="getQuestionClasses($props.healthRecord.values, 'tripleNegativeCancer')"></i>
        Wurden Sie mit einem Triple Negativ Brustkrebs diagnostiziert?
      </div>
    </div>

    <!-- Generic questionnaire -->
    <div v-if="$props.healthRecord.subType != 'Cancer'">
      <div class="col mb-3" v-for="val in $props.healthRecord.values" :key="val">
        <div class="lead">{{ val.value + ' ' + val.unit}}</div>
        <div style="font-size:0.9rem;font-weight:600">{{ val.name }}</div>
      </div>
    </div>

  </div>

  <!-- Notes -->
  <div v-if="$props.healthRecord.type == 'Note'">
      <span class="lead">{{ $props.healthRecord.values[0].value }}</span>
      <div style="font-size:0.9rem;font-weight:600">Notiz</div>
  </div>

  <!-- Report -->
  <div v-if="$props.healthRecord.type == 'Report'">
      <a href="#healthrecords" class="link-primary lead" 
        @click="downloadFile($props.healthRecord.uuid, lookupValueByName($props.healthRecord.values, 'internalFileKey'), lookupValueByName($props.healthRecord.values, 'originalFileName'))">
          <i class="bi-file-pdf me-2"></i>{{ lookupValueByName($props.healthRecord.values, 'originalFileName') }}
      </a>
      <div style="font-size:0.9rem;font-weight:600">Datei</div>
  </div>

</div>

</template>

<script>

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'HealthRecord',
  emits: [
    'onUpdated'
  ],
  props: {
    healthRecord: Object
  },
  setup(props, { emit }) {

    log.trace("HealthRecord:setup() (props.healthRecord: {})", props.healthRecord);

    function downloadFile(healthRecordUuid, internalFileKey, originalFileName) {
      api.downloadHealthRecordFile(healthRecordUuid, internalFileKey, originalFileName);
    }

    function onReviewed() {
      log.trace("HealthRecord:setReviewed() entered")
      api.setHealthRecordReviewed(props.healthRecord.uuid)
      .then(() => {
        emit('onUpdated');
      });
    }

    function getQuestionClasses(values, name) {
      const val = lookupValueByName(values, name);
      if (val != null) {
        return val === 'true' ? 'bi-check-circle text-primary' : 'bi-x-circle text-danger';
      }
      else {
        return 'bi-question-circle text-info';
      }
    }

    function lookupValueByName(values, name) {
      for (let i = 0; i < values.length; i++) {
         if (values[i].name === name) {
           return values[i].value;
         }
      }
      return null;
    }

    return {
      onReviewed,
      downloadFile,
      getQuestionClasses,
      lookupValueByName
    };
  }
};
</script>

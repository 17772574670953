<template>

<div class="border shadow">
<slot></slot>
<table class="table table-hover rounded">

  <thead class="table-light">
    <tr>
      <th scope="col">Patient</th>
      <th scope="col">Pre-Anamnese</th>
      <th scope="col">Status</th>
      <th scope="col"></th>
    </tr>
  </thead>

  <tbody>
    <tr v-for="consultation in state.consultations" :key="consultation" @click="$router.push({ path: `/consultations/${consultation.uuid}` });">

      <td class="clickable">
        <div class="fw-bold">
          {{ consultation.patient.name + ' ' + consultation.patient.familyName }}
          <i v-if="consultation.patient.gender == 'Male'" class="bi-gender-male" ></i>
          <i v-if="consultation.patient.gender == 'Female'" class="bi-gender-female" ></i>
          <i v-if="consultation.patient.gender == 'Diverse'" class="bi-gender-ambiguous"> </i>
        </div>
        <div>{{ $fmt.fromFormatToDate(consultation.patient.birthdate, "yyyy-MM-dd") }}</div>
      </td>

      <td class="clickable">
        <div class="fw-bold">{{ consultation.patientReportedHealthRecord.subType }}</div>
        <div>{{ consultation.patientReportedHealthRecord.type }}</div>
      </td>

      <td class="clickable">
        <div class="">{{ consultation.detailedStatus.message }}</div>
        <!-- This one is currently more confusing then helpful
        <div>{{ $fmt.fromMillisToDateTime(consultation.detailedStatus.at) }}</div>
        -->
      </td>

      <td>

        <span v-if="reviewPreAnamnesis(consultation)" class="badge bg-warning clickable me-2" @click="$router.push({ path: `/consultations/${consultation.patient.uuid}`, hash: '#healthrecords' });">
          Pre-Anamnese begutachten
        </span>

        <span v-if="giveIndication(consultation)" class="badge bg-primary clickable me-2" @click="$router.push({ path: `/consultations/${consultation.patient.uuid}` });">
          Indikation stellen
        </span>

        <span v-if="isOverdue(consultation)" class="badge bg-danger clickable me-2" @click="$router.push({ path: `/consultations/${consultation.patient.uuid}` });">
          Termin überfällig
        </span>

        <span v-if="isNoIndication(consultation)" class="badge bg-warning clickable me-2" @click="$router.push({ path: `/consultations/${consultation.patient.uuid}` });">
          Keine Indikation
        </span>

      </td>

    </tr>
  </tbody>
</table>
</div>

</template>

<script>

import { reactive } from 'vue'
import { DateTime } from 'luxon'

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'ConsultationTable',
  setup() {

    const state = reactive({
      consultations: [],
    });

    function fromStatusToMessage(consultation) {
      switch (consultation.status) {
        case 'Appointment:Pending': return "Termin für " + DateTime.fromMillis(consultation.appointment.slotAt).setLocale("de-de").toLocaleString() + " geplant";
        case 'Appointment:CallStarted': return "Gespräch ist gestartet"
        case 'Appointment:CallEstablished': return "Termin durchgeführt"
        case 'Appointment:Cancelled': return "Termin wurde abgesagt"
        default:
          return consultation.status;
      }
    }

    function query() {
      api.queryConsultations(0, -1).then(response => {
        state.consultations = response.items;
        log.trace("ConsultationTable:query() Fetched consultations: {}", state.consultations);
      });
    }

    function reviewPreAnamnesis(consultation) {
      return !consultation.patientReportedHealthRecord.reviewedBy && consultation.appointment.status == 'Pending' && !consultation.appointment.overdue;
    }

    function giveIndication(consultation) {
      return consultation.appointment.status == 'CallEstablished' && consultation.noIndicationHealthRecord == null && consultation.order == null;
    }

    function isOverdue(consultation) {
      return (consultation.appointment.status == 'Pending' || consultation.appointment.status == 'CallStarted') && consultation.appointment.overdue;
    }

    function isNoIndication(consultation) {
      return consultation.noIndicationHealthRecord != null;
    }

    query();

    return {
      state,
      fromStatusToMessage,
      reviewPreAnamnesis,
      giveIndication,
      isOverdue,
      isNoIndication
     };
  }
}
</script>

<template>

<!-- Large Screen Stepper -->
<div class="pt-3 d-none d-lg-block" style="background:#ffffffee">

  <div class="row justify-content-md-center">
    <div class="col-3 align-self-end">
      <span :class="{ 'fw-bold' : $props.page == PAGES.MedicalHistory, 'text-muted' : $props.page < PAGES.MedicalHistory }">Kranken&shy;geschichte</span>
      <div class="progress rounded mt-2" style="height: 6px">
        <div class="progress-bar" :class="{ 'w-100' : $props.page >= PAGES.MedicalHistory}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>

    <div class="col-3 align-self-end">
      <span :class="{ 'fw-bold' : $props.page == PAGES.Appointment, 'text-muted' : $props.page < PAGES.Appointment }">Ter&shy;min</span>
      <div class="progress rounded mt-2" style="height: 6px">
        <div class="progress-bar" :class="{ 'w-100' : ($props.page >= PAGES.Appointment) }" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>    
    </div>

    <div class="col-3 align-self-end">
      <span :class="{ 'fw-bold' : $props.page == PAGES.Contact, 'text-muted' : $props.page < PAGES.Contact }">Kon&shy;takt</span>
      <div class="progress rounded mt-2" style="height: 6px">
        <div class="progress-bar" :class="{ 'w-100' : $props.page >= PAGES.Contact}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>

    <div class="col-3 align-self-end">
      <span :class="{ 'fw-bold' : $props.page == PAGES.Review, 'text-muted' : $props.page < PAGES.Review }">Über&shy;prüfung</span>
      <div class="progress rounded mt-2" style="height: 6px">
        <div class="progress-bar" :class="{ 'w-100' : $props.page >= PAGES.Review}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</div>

<!-- Small Screen Stepper -->
<div class="pt-3 d-lg-none" style="background:#ffffffee">

  <!-- Step 1: Medical History -->
  <div v-if="$props.page == PAGES.MedicalHistory" class="d-flex">
    <div class="flex-shrink-0 me-2">
      <i class="btn bi-file-medical fs-1" ></i>
    </div>
    <div class="flex-column my-auto">
      <div class="text-muted fs-6 fw-bold">Schritt 1/4</div>
      <div class="fs-5">Krankengeschichte</div>
    </div>
  </div>

  <!-- Step 2: Appointment -->
  <div v-if="$props.page == PAGES.Appointment" class="d-flex">
    <div class="flex-shrink-0 me-2">
      <i class="btn bi-calendar-check fs-1" ></i>
    </div>
    <div class="flex-column my-auto">
      <div class="text-muted fs-6 fw-bold">Schritt 2/4</div>
      <div class="fs-5">Termin</div>
    </div>
  </div>

  <!-- Step 3: Contact -->
  <div v-if="$props.page == PAGES.Contact" class="d-flex">
    <div class="flex-shrink-0 me-2">
      <i class="btn bi-person-lines-fill fs-1" ></i>
    </div>
    <div class="flex-column my-auto">
      <div class="text-muted fs-6 fw-bold">Schritt 3/4</div>
      <div class="fs-5">Kontaktdaten</div>
    </div>
  </div>

  <!-- Step 4: Review -->
  <div v-if="$props.page == PAGES.Review" class="d-flex">
    <div class="flex-shrink-0 me-2">
      <i class="btn bi-check-circle fs-1" ></i>
    </div>
    <div class="flex-column my-auto">
      <div class="text-muted fs-6 fw-bold">Schritt 4/4</div>
      <div class="fs-5">Überprüfung</div>
    </div>
  </div>

  <!-- Total progress -->
  <div class="progress rounded mt-2" style="height: 6px">
    <div class="progress-bar" :style="{ width: ($props.page + 1) * 25 + '%'}" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
  </div>

</div>
</template>

<script>

export default {
  name: 'AppointmentProgress',
  props: {
    page: { 
      type: Number,
      default: 0
    }
  },
  setup() {

    const PAGES = { MedicalHistory: 0, Appointment: 1, Contact: 2, Review: 3, Confirmation: 4 };

    return {
      PAGES
    };
  }
};
</script>

<template>

<Toolbar :activeMenu="'Orders'"/>

<div class="w-100 overflow-auto">

<div class="header-with-tabs sticky-top">
  
  <div class="container px-5 pt-4">

    <!-- Headline -->
    <h1 class="display-5">
      <button class="btn btn-sm btn-primary float-end" @click="queryAndRefreshOrders()">Lieferstatus aktualisieren</button>
      <i class="bi-box-seam pe-2 text-primary" role="img" aria-label="Appointments"></i>
      Laboraufträge
    </h1>
    <span class="lead">Hier finden Sie alle Laboraufträge mit Status auf einen Blick</span>

    <!-- Tabs -->
    <ul class="nav mt-4">
      <li class="nav-item ps-4 pe-4" :class="{ active :  getTab() == 'commission' }">
        <a class="nav-link" href="#" @click="showTab('commission')">Kommissionierung</a>
      </li>
      <li class="nav-item ps-4 pe-4" :class="{ active : getTab() == 'outbound' }">
        <a class="nav-link" href="#" @click="showTab('outbound')">Zusendung</a>
      </li>
      <li class="nav-item ps-4 pe-4" :class="{ active : getTab() == 'inbound' }">
        <a class="nav-link" href="#" @click="showTab('inbound')">Rücksendungen</a>
      </li>
    </ul>    

  </div>
</div>

<div class="container px-5 pb-5">
  <router-view v-slot="{ Component }">
    <component ref="orderListView" :is="Component" />
  </router-view>
</div>

</div>
</template>

<script>

import { ref } from 'vue';
import { useRoute, useRouter} from 'vue-router';

import Toolbar from '@/components/Toolbar.vue'

export default {
  name: 'OrderList',
  components: {
    Toolbar,
  },
  setup() {

    const route = useRoute();
    const router = useRouter();
    
    const orderListView = ref(null);

    function getTab() {
      let path = route.path.split('/').filter(s => s.length > 0);
      if (path.length > 1) {
        return path[1];
      }
      return '';
    }

    function showTab(detail) {
      router.push(`/orders/${detail}`);
    }

    function queryAndRefreshOrders() {
      orderListView.value.queryOrders(true);
    }

    return {
      orderListView,
      getTab,
      showTab,
      queryAndRefreshOrders
    };
  }
};
</script>

<template>

<Toolbar :activeMenu="'Schedule'"/>

<NewSlot ref="newSlotDialog" @onClosed="querySlots();"></NewSlot>

<EditSlots ref="editSlotsDialog" @onUpdated="querySlots();"></EditSlots>

<div class="w-100 overflow-auto">

<!-- Header --> 
<div class="header">
  <div class="container px-5 pt-5">

    <!-- Headline -->
    <h1 class="display-5">
      <i class="bi-calendar2-check pe-2 text-primary" role="img" aria-label="Appointments"></i>
      Sprechstundenplan
    </h1>
    <span class="lead">Planen Sie hier bequem Ihere Verfügbarkeiten</span>

  </div>
</div>

<!-- Body -->
<div class="container px-5 pb-5">

  <!--Actions -->
  <button class="btn btn-primary float-end" @click="showNewSlotModal">
    <i class="bi-plus-circle pe-2"></i>Sprechstunden hinzufügen
  </button>

  <!--Calendar navigation -->
  <div class="d-flex mt-5 mb-2">
      <button @click="onNow" class="btn btn-outline-primary rounded me-4">Heute</button>
      <i @click="onPrevWeek" class="btn bi-chevron-left" ></i>
      <i @click="onNextWeek" class="btn bi-chevron-right me-4"></i>
      <div class="mt-1 lead">
        Woche von {{ $fmt.toFormat(state.weekDays[0], "DDD") + ' bis ' + $fmt.toFormat(state.weekDays[6], "DDD") }}
      </div>
  </div>

  <!-- Calendar -->
  <div class="border shadow mt-4">
    
    <!-- Headline -->
    <div class="row g-0 bg-light sticky-top py-4 border-bottom">
      <div class="col"></div>
      <div class="col text-center" v-for="day in state.weekDays" :key="day">
          <span class="lead" :class="{ 'fw-bold' : isToday(day) }">{{ WEEKDAYS[toMonday(day.getDay())].de }}</span>
          <span class="mt-2 slotAvailable">{{ day.getDate() }}</span>
      </div>
    </div>

    <!-- Hours per day -->
    <div class="row g-0 border-bottom">

      <!-- Time colummn -->
      <div class="col dateGrid" style="position:relative;height:60rem;width:4rem;">
        <div v-for="i in HOURS_PER_DAY" :key="i" class="d-flex align-content-center text-center" :style="{ height: HEIGHT_PER_HOUR + 'rem' }">
            <span class="fs-5 text-muted align-self-center mx-auto">{{ i + START_HOUR - 1 + ":00" }}</span>
        </div>
      </div>

      <!-- Day columns (of current week) -->
      <div class="col position-relative dateGrid" :style="{ height: HEIGHT_PER_HOUR * HOURS_PER_DAY + 'rem' }" v-for="day in state.weekDays" :key="day">

        <transition-group name="list">
        <div v-for="slot in getSlotsAt(day)" :key="slot" class="position-absolute ps-1 pe-1" :style="{ top: ((new Date(slot.at).getHours() - START_HOUR) * HEIGHT_PER_HOUR) + 'rem', height: (slot.length / 60 * HEIGHT_PER_HOUR) + 'rem' }" style="width:100%">
          <div class="w-100 h-100 bg-primary opacity-50 rounded overflow-hidden" style="cursor:pointer" @click="editSlotTemplates($event, new Date(slot.at), HEIGHT_PER_HOUR);">
          </div>
        </div>
        </transition-group>

      </div>

    </div>
  </div>

</div>
</div>
</template>

<script>

import { computed, reactive, ref } from 'vue'

import { DateTime } from 'luxon';

import Toolbar from '@/components/Toolbar.vue'
import NewSlot from './NewSlot.vue';
import EditSlots from './EditSlots.vue';

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'Schedule',
  components: {
     Toolbar,
     NewSlot,
     EditSlots,
  },
  created() {
    this.WEEKDAYS = [
      { de: "Mo", deLong: "Montag",     value: 0 },
      { de: "Di", deLong: "Dienstag",   value: 1 },
      { de: "Mi", deLong: "Mittwoch",   value: 2 },
      { de: "Do", deLong: "Donnerstag", value: 3 },
      { de: "Fr", deLong: "Freitag",    value: 4 },
      { de: "Sa", deLong: "Samstag",    value: 5 },
      { de: "So", deLong: "Sonntag",    value: 6 }
    ];

    // const TIME_FORMAT = "HH:mm";
    // const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";

    this.START_HOUR = 6;
    this.END_HOUR = 20;
    this.HOURS_PER_DAY = this.END_HOUR - this.START_HOUR + 1;
    this.HEIGHT_PER_HOUR = 4.0; // in rem
    this.HEIGHT_PER_HOUR_REM = '4rem';
  },
  setup() {

    const newSlotDialog = ref(null);
    const editSlotsDialog = ref(null);

    const state = reactive({

      startAt: new Date(),

      startAtMonday: computed(() => {
        let monday = new Date(state.startAt.getTime());
        if (state.startAt.getDay() == 0) {
          monday.setDate(state.startAt.getDate() - 6);
        }
        else {
          monday.setDate(state.startAt.getDate() - state.startAt.getDay() + 1);
        }
        return monday;
      }),

      weekDays: computed(() => {
        let days = [];
        for (let i = 0; i < 7; i++) {
          days[i] = new Date(state.startAtMonday.getTime());
          days[i].setDate(state.startAtMonday.getDate() + i);
        }
        return days;
      }),

      slots: [
      ],
    });

    function toMonday(day) {
      return day == 0 ? 6 : day -1;
    }

    function isToday(date) {
      return isSameDay(date, new Date());
    }

    function isSameDay(date, other) {
      return (other.getFullYear() == date.getFullYear() && other.getMonth() == date.getMonth() && other.getDate() == date.getDate());
    }

    function getSlotsAt(date) {
        let slots = [];
        state.slots.forEach( slot => {
          if (isSameDay(new Date(slot.at), date)) {
            slots.push(slot);
          }
        })
        return slots;
    }

    function onNow() {
      state.startAt = new Date();
      querySlots();
    }

    function onPrevWeek() {
      state.startAt = new Date(state.startAt.getFullYear(), state.startAt.getMonth(), state.startAt.getDate() - 7);
      querySlots();
    }

    function onNextWeek() {
      state.startAt = new Date(state.startAt.getFullYear(), state.startAt.getMonth(), state.startAt.getDate() + 7);
      querySlots();
    }

    function showNewSlotModal() {
      newSlotDialog.value.show();
    }

    function querySlots() {
      api.querySlots(state.weekDays[0], state.weekDays[6])
        .then(data => {
          state.slots = data;
          log.trace("Schedule:querySlots() Fetched slots: {}", state.slots);
        });
    }

    function editSlotTemplates(e, at, heightPerHour) {

      // lets find out on which time the user clicked exactly, so...
      // I. convert offsetY pixel to rem
      let remOffsetY = parseFloat(e.offsetY) / parseFloat(window.getComputedStyle(e.target).fontSize);

      // II. Convert remOffsetY to minutes (where heightPerHour in rem)
      let minutes = remOffsetY / heightPerHour * 60;

      // III. Add minutes to the start time of the clicked slot
      let exactAt = DateTime.fromJSDate(at).plus({ minutes: minutes }).toJSDate();
      log.trace("Schedule:editSlotTemplates() Edit slot at exactly at: {}", exactAt);

      editSlotsDialog.value.show(exactAt);
    }

    querySlots();

    return {
      newSlotDialog,
      editSlotsDialog,
      state,
      toMonday,
      isToday,
      getSlotsAt,
      onNow,
      onPrevWeek,
      onNextWeek,
      querySlots,
      showNewSlotModal,
      editSlotTemplates
    };
  }
};
</script>

<style scoped>

.dateGrid {
    background-image: repeating-linear-gradient(#ddd 0 1px, transparent 1px 100%);
    background-size: 4rem 4rem;
}

.dayInPast {

    display: block;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;

    font-size: 1em;
    color: darkgray;

    margin: auto;
}

.slotAvailable {

    display: block;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;

    -moz-border-radius: 50%;
    border-radius: 50%;

    background-color: #17C3B2;
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    color: white;

    margin: auto;
    cursor: pointer;
}

.slotAvailable:hover {
    background-color: #227c9d;
}

.noSlotAvailable {

    display: block;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;

    font-size: 1em;
    font-weight: 600;

    margin: auto;
}

.slotDateBooked {
    background-color: #227c9d !important;
}

.timeSlot {
    cursor: pointer;
}

.timeSlot:hover {
  background-color: #17C3B2;
  color: white !important;
}

.timeSlotBooked {
  background-color: #227c9d !important;
  border-color: #227c9d !important;
  color: white !important;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-enter-from {
  opacity: 0;
}

</style>

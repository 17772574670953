<template>

  <CommissionDialog ref="commissionDialog"/>
  <TrackingDialog ref="trackingDialog" @onUpdated="onOrderUpdated();"/>

  <div v-if="$props.view == 'Commission'" class="mt-5">
    <div v-if="state.orders.length == 0" class="display-6 text-center">
      <span>Aktuell sind keine Aufträge zur Kommissionierung offen</span>
    </div>
    <div v-if="state.orders.length > 0">
      <OrderTable :orders="state.orders" @onTracking="onTracking($event)" @onCommission="onCommission($event)" @onUpdated="onOrderUpdated()" >
        <div class="bg-light p-3">
          <button class="btn btn-sm btn-primary float-end" @click="onCommission(state.orders)">Versanddatei erstellen &amp; Etiketten drucken</button>
          <span class="fs-4 lead">Bereit für Kommissionierung</span>
        </div>
      </OrderTable>
    </div>
  </div>

  <div v-if="$props.view == 'Outbound'" class="mt-5">
    <div v-if="state.orders.length == 0" class="display-6 text-center">
      <span>Aktuell sind keine Aufträge auf dem Weg zum Patieten</span>
    </div>
    <div v-if="state.orders.length > 0">
      <OrderTable :orders="state.orders" :withActions="true" @onUpdated="onOrderUpdated()">
        <div class="bg-light p-3">
          <span class="fs-4 lead">Aktuelle Sendungen zum Patient</span>
        </div>
      </OrderTable>
    </div>
  </div>

  <div v-if="$props.view == 'Inbound'" class="mt-5">
    <div v-if="state.orders.length == 0" class="display-6 text-center">
      <span>Aktuell sind keine Aufträge auf dem Weg zurück ins Labor</span>
    </div>
    <div v-if="state.orders.length > 0">
      <OrderTable :orders="state.orders" :withActions="true" @onUpdated="onOrderUpdated()">
        <div class="bg-light p-3">
          <span class="fs-4 lead">Aktuelle Rücksendungen ins Labor</span>
        </div>
      </OrderTable>
    </div>
  </div>

</template>

<script>

import { ref, watch, reactive } from 'vue'

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

import CommissionDialog from '@/views/order/CommissionDialog.vue'
import TrackingDialog from '@/views/order/TrackingDialog.vue'

// import orderUtils from './OrderUtils';

import OrderTable from './OrderTable.vue';

export default {
  name: 'OrderListView',
  emits: [ 'onTracking', 'onCommission' ],
  props: {
    view : String,
  },
  components: {
    CommissionDialog,
    TrackingDialog,
    OrderTable,
  },
  setup(props) {

    watch(() => props.view, (newView, oldView) => { 
      log.trace("OrderListView:setup() Prop view changed from {} to {}", oldView, newView);
      queryOrders(false);
    });

    const state = reactive({
      orders: [],
    });

    const commissionDialog = ref(null);
    const trackingDialog = ref(null);

    function onCommission(orders) {
      commissionDialog.value.show(orders);
    }

    function onTracking(order) {
      trackingDialog.value.show(order);
    }

    function onOrderUpdated() {
      queryOrders(false);
    }

    function queryOrders(refreshShippingStatus) {
      log.trace("OrderListView:queryOrders() Query Status: {}, With Refreh: {}", mapViewTotOrderStatus(), refreshShippingStatus);
      api.queryOrders(mapViewTotOrderStatus(), refreshShippingStatus).then(response => {
        state.orders = response.items;
        log.trace("OrderListView:queryOrders() Returned: {}", state.orders);
      });
    }

    function mapViewTotOrderStatus() {
      switch (props.view) {
        case 'Commission': return 'ReadyForCommission';
        case 'Outbound': return 'InShippingToPatient';
        case 'Inbound': return 'InShippingOrShippedToLab';
        default: return 'All';
      }
    }

    queryOrders(false);
    
    return {
      commissionDialog,
      trackingDialog,
      state,
      queryOrders,
      onCommission,
      onTracking,
      onOrderUpdated,
    };
  }
};
</script>
<template>
<div class="d-flex input-group has-validation">

<select class="form-select flex-fill" @change="onChanged()" v-model="state.day" required>
  <option :selected="!state.day" disabled >- Tag wählen -</option>
  <option v-for="day in 31" :key="day" :value="day" :selected="day == state.day">{{ day }}.</option>
</select>
<select class="form-select ms-2 flex-fill" @change="onChanged()" v-model="state.month" required>
  <option :selected="!state.month" disabled >- Monat wählen - </option>
  <option v-for="month in months" :key="month" :value="month.value" :selected="month == state.month">{{ month.name }}</option>
</select>
<select class="form-select ms-2 flex-fill" @change="onChanged()" v-model="state.year" required>
  <option :selected="!state.year" disabled >- Jahr wählen -</option>
  <option v-for="year in ($props.yearFrom - $props.yearTo)" :key="year" :value="$props.yearFrom - year" :selected="$props.yearFrom - year == state.year">{{ $props.yearFrom - year }}</option>
</select>

</div>
</template>
<script>

import { reactive } from 'vue'

import log from '@/modules/logging.js';

export default {
  name: 'DatePicker',
  props: { 
    pickedDate: {
      type: String,
      default: null
    },
    yearFrom: {
      type: Number,
      default: 2022 - 18
    },
    yearTo: {
      type: Number,
      default: 2022 - 90
    },
  },
  emits: [ 'onDatePicked' ],
  setup(props, { emit }) {

    const months = [ 
      { name: "Januar", value: 1 }, 
      { name: "Februar", value: 2 }, 
      { name: "März", value: 3 }, 
      { name: "April", value: 4 }, 
      { name: "Mai", value: 5 }, 
      { name: "Juni", value: 6 }, 
      { name: "Juli", value: 7 }, 
      { name: "August", value: 8 }, 
      { name: "September", value: 9 }, 
      { name: "Oktober", value: 10 }, 
      { name: "November", value: 11 }, 
      { name: "Dezember", value: 12 }, 
    ];

    const state = reactive({

      year: props.pickedDate != null ? parseInt(props.pickedDate.substring(0, 4)) : null,
      month: props.pickedDate != null ? parseInt(props.pickedDate.substring(5, 7)) : null,
      day: props.pickedDate != null ? parseInt(props.pickedDate.substring(8, 10)) : null,

    });

    function onChanged() {

      log.trace("DatePicker:onChange() {}", state);

      //
      // try to construct a date object
      if (state.day != null && state.month != null && state.year != null) {

        //
        // create date, corrected by timezone offset and check, if date is valid
        const pickedDate = new Date(state.year, state.month -  1, state.day);

        if (pickedDate.getFullYear() == state.year && 
            pickedDate.getMonth() == state.month - 1 && 
            pickedDate.getDate() == state.day) {

            const pickedDateStr = `${state.year}-${zeroPad(state.month, 2)}-${zeroPad(state.day, 2)}`;
            log.trace("DatePicker:onChange() Date picked {}", pickedDateStr);

            emit('onDatePicked', pickedDateStr);
            return;
        }
      }

      log.trace("DatePicker:onChange() No valid date picked");
      emit('onDatePicked', null);
    }

    function zeroPad(num, places) {
      return new String(num).padStart(places, '0');
    }

    log.trace("DatePicker:setup() Initial state: {}, props: {}", state, props);

    return {
      months,
      state,
      onChanged,
    };
  }
};
</script>

<style scoped>

</style>
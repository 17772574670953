<template>

<Toolbar :activeMenu="'Consultations'"/>

<div class="w-100 overflow-auto">

<div class="header-with-tabs sticky-top">

  <BusyIndicator :busy="state.busy > 0"></BusyIndicator>

  <div class="container px-5 pt-5">

    <div> 

      <!-- Headline -->
      <h1 class="display-6">
        {{ state.consultation.patient.name }} {{ state.consultation.patient.familyName }}
      </h1>
      <div class="form-label">

        <span class="d-inline-block me-3">
          <i class="bi-asterisk pe-2 text-primary" role="img"></i>{{ $fmt.fromFormatToDate(state.consultation.patient.birthdate, "yyyy-MM-dd") }}
        </span>

        <span class="d-inline-block me-3">
          <i class="bi-gender-male pe-2 text-primary" role="img"></i>{{ $fmt.toGender(state.consultation.patient.gender, state.consultation.patient.fullName) }}
        </span>

        <span class="d-inline-block me-3">
          <i class="bi-geo-alt pe-2 text-primary" role="img"></i>
          <span v-if="state.consultation.patient.addressComplete">{{ state.consultation.patient.address.fullAddress }}</span>
          <span v-if="!state.consultation.patient.addressComplete" class="badge bg-danger">Adresse nicht erfasst</span>
        </span>

        <span class="d-inline-block me-3">
          <i class="bi-shield-shaded pe-2 text-primary" role="img"></i>
          <span v-if="state.consultation.patient.insuranceComplete">{{ state.consultation.patient.insurance.instituteName }}</span>
          <span v-if="!state.consultation.patient.insuranceComplete" class="badge bg-danger">Versicherung nicht erfasst</span>
        </span>
      </div>
    </div>

    <!-- Tabs -->
    <ul class="nav mt-4">
      <li class="nav-item ps-4 pe-4" :class="{ active : getTab() == '' }">
        <a class="nav-link" href="#" @click="showTab()">Aktuelle Beratung</a>
      </li>
      <li class="nav-item ps-4 pe-4" :class="{ active : getTab() == 'patient' }">
        <a class="nav-link" href="#" @click="showTab('patient')">Personendaten</a>
      </li>
      <li class="nav-item ps-4 pe-4" :class="{ active : getTab() == 'medicalHistory' }">
        <a class="nav-link" href="#" @click="showTab('medicalHistory')">Gesundheitsakte</a>
      </li>
    </ul>    

  </div>

</div>

<!-- sub page -->
<div class="container px-5 pb-5">
  <router-view @onUpdated="refresh()" @onBusy="setBusy($event)"></router-view>
</div>

</div>
</template>

<script>

import { reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Toolbar from '@/components/Toolbar.vue'
import BusyIndicator from '@/components/BusyIndicator';

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'Consultation',
  components: {
    Toolbar,
    BusyIndicator,
  },
  setup() {

    //
    // keep track of route
    const route = useRoute();
    watch(() => route.path, () => {
      query();
    });
    const router = useRouter();

    const state = reactive({

      // busy indicator
      busy: 0,

      // the current consultation
      consultation: {
        currentStatus: {
          message: "",
        },
        appointment: {
          slotAt: 0,
          detailedStatus: {
            message: "",
          }
        },
        patient: {
          fullName: "",
          birthDate: 0,
          address: { },
          insurance: { }
        },
      }
    });

    function extractConsultationUuidFromRoute() {
      const path = route.path.split("/").filter(s => s.length > 0);
      if (path[0] == 'consultations' && path[1]) {
        return path[1];
      }
      return null;
    }

    function query() {
      let uuid = extractConsultationUuidFromRoute();
      if (uuid) {
        queryByConsultationUuid(extractConsultationUuidFromRoute());
      }
    }

    function queryByConsultationUuid(uuid) {
      setBusy(true);
      api.getConsultation(uuid).then(response => {
        log.trace("Consultation:queryByConsultationUuid() Fetched consultations: {}", response);
        state.consultation = response;
        setBusy(false);
      });
    }

    function refresh() {
      query(state.consultation.uuid);
    }

    function getTab() {
      let path = route.path.split('/').filter(s => s.length > 0);
      if (path.length > 2) {
        return path[2];
      }
      return '';
    }

    function showTab(detail) {
      if (detail) {
        router.push(`/consultations/${extractConsultationUuidFromRoute()}/${detail}`);
      }
      else {
        router.push(`/consultations/${extractConsultationUuidFromRoute()}`);
      }
    }

    function setBusy(busy) {
      busy ? state.busy++ : state.busy--;
    }

    query();

    return {
      state,
      getTab,
      showTab,
      refresh,
      setBusy,
    };
  }
};
</script>
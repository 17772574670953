/**
 * Assets that are dynamically displayed. In some cases this assets are optimized away, 
 * so we need a central place where we invclude this assets statically
 */

export default {
  logo: require("@/assets/logo.png"),
  userPlaceholder: require("@/assets/user-diverse.svg"),
  maleUserPlaceholder: require("@/assets/user-male.svg"),
  femaleUserPlaceholder: require("@/assets/user-female.svg"),
}

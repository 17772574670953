<template>
<div>
<PatientOverview :patient="state.consultation.patient" @onBusy="$emit('onBusy', $event)" @onUpdated="$emit('onUpdated')"></PatientOverview>
</div>
</template>

<script>

import { reactive, watch } from 'vue'
import { useRoute } from 'vue-router'

import PatientOverview from '@/views/patient/PatientOverview.vue';

import api from '@/modules/api.js';

export default {
  name: 'ConsultationPatient',
  emits: [ 'onBusy', 'onUpdated' ],
  components: {
    PatientOverview,
  },
  setup(props, { emit }) {

    //
    // keep track of route
    const route = useRoute();
    watch(() => route.path, () => {
      resolveRoute();
    });

    const state = reactive({
      
      // the current patient
      consultation: {
        patient: {
          fullName: "",
          birthDate: 0,
          address: { },
          insurance: { }
        },
      }
    });

    function resolveRoute() {
      const path = route.path.split("/").filter(s => s.length > 0);
      if (path[0] == 'consultations' && path[1]) {
        query(path[1]);
      }
    }

    function query(uuid) {
      emit('onBusy', true);
      api.getConsultation(uuid).then(response => {
        state.consultation = response;
        emit('onBusy', false);
      });
    }

    resolveRoute();

    return {
      state,
    };
  }
};
</script>
<template>

<div class="border shadow">
<slot></slot>
<table class="table table-hover rounded">

    <thead class="table-light">
      <tr>
        <th scope="col" style="width:20%">Termin am</th>
        <th v-if="$props.withName" scope="col" style="width:20%">Name</th>
        <th scope="col" style="width:20%">Details</th>
        <th scope="col" style="width:20%"></th>
      </tr>
    </thead>

    <tbody>
      <tr class="clickable" v-for="appointment in $props.appointments" :key="appointment">

        <td @click="gotoPatient(appointment.consultation.uuid)" scope="row">
          {{ $fmt.fromMillisToDateTime(appointment.slotAt) }}
        </td>

        <td v-if="$props.withName" @click="gotoPatient(appointment.consultation.uuid)" style="width:20%">
          {{ appointment.patient.name + ' ' + appointment.patient.familyName }}
        </td>

        <td>
          <span v-if="appointment.overdue" class="badge bg-danger">Ist überfällig</span>
        </td>

        <!-- Drop down Action -->
        <td>
          <div class="dropdown float-end">
            <a class="btn btn-outline-primary btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
              Termin
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <a class="dropdown-item" href="#">
                  <i class="bi-envelope me-2" role="img"></i>
                  Einladung erneut senden
                </a>
              </li>
            </ul>
          </div>

        </td>
      </tr>
    </tbody>
  </table>
</div>

</template>

<script>

import { useRouter } from 'vue-router'

export default {
  name: 'AppointmentTable',
  emits: [
    'onUpdated'
  ],
  props: {
    appointments: Array,
    withName: {
      type: Boolean,
      default: true
    }
  },
  setup() {

    const router = useRouter();

    function gotoPatient(uuid) {
      router.push({ path: `/consultations/${uuid}` });
    }

    return {
      gotoPatient,
    };
  }
};
</script>


<template>

<!-- Order testkit dialog -->
<div class="modal" ref="orderTestKitDialog" tabindex="-1">
<div class="modal-dialog modal-lg">
  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">Entnahmesystem zusenden</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="modal-body">
      
      <div class="row">

        <!-- Addresee -->
        <div class="col d-flex p-4">
          <i class="bi-person-lines-fill fs-4 me-3" role="img"></i>
          <div>
            <span class="fs-4">Empfänger</span>
            <div class="mt-3">
              <div class="fw-bold lead">{{ $props.consultation.patient.name + ' ' + $props.consultation.patient.familyName }}</div>
              <div class="lead">{{ $props.consultation.patient.address.street }}</div>
              <div class="lead">{{ $props.consultation.patient.address.zip + ' ' + $props.consultation.patient.address.city }}</div>
            </div>
          </div>
        </div>

        <!-- Select Panel -->
        <div class="col d-flex p-4">

          <i class="bi-box-seam fs-4 me-3" role="img"></i>
          <div>
            <span class="fs-4">Panel</span>
            <div class="mt-3">
              <select v-model="state.panel" class="form-select">
                <option disabled value="">Panel auswählen...</option>
                <option value="CancerKit">Krebs</option>
                <option value="DiabetesKit">Diabetes</option>
              </select>
            </div>
          </div>
        </div>

      </div>

      <div class="row mt-3">

        <!-- Consent -->
        <div class="col d-flex p-4">
          <i class="bi-clipboard-check fs-4 me-3" role="img"></i>
          <div>
            <span class="fs-4">Einwilligung nach GenDG</span>
              <div class="form-check lead mt-3">
                <input v-model="state.consent" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  <b>Ja</b>, ich habe den Patient gemäß GenDG aufgeklärt.
                </label>
              </div>
            </div>
        </div>
      </div>

    </div>

    <div class="modal-footer">              
      <button class="btn btn-primary mt-4" :class="{ 'disabled': state.panel == '' || state.consent == false }" @click="createNewOrder()">Jetzt zustellen</button>
    </div>
  </div>
</div>
</div>

</template>

<script>

import { reactive, ref } from 'vue'

import { Modal } from 'bootstrap'

import api from '@/modules/api.js';

export default {
  name: 'OrderTestKitDialog',
  emits: [
    'onTestKitOrdered'
  ],
  props: {
    consultation: Object
  },
  setup(props, { emit }) {

    //
    // ref to order test kit dialog
    const orderTestKitDialog = ref(null);
    var orderTestKitModal;

    const state = reactive({
      
      // busy indicator
      busy: false,

      //
      // panel
      panel: "",
      consent: false,
    });


    function show() {
      state.consent = false;
      state.panel = "";
      orderTestKitModal = new Modal(orderTestKitDialog.value);
      orderTestKitModal.show();
    }

    function createNewOrder() {
      state.busy = true;
      api.createOrder(props.consultation.uuid, {
        panel: state.panel,
      }).then(() => { 
        orderTestKitModal.hide();
        state.busy = false;
        emit('onTestKitOrdered')
      });
    }

    return {
      state,
      orderTestKitDialog,
      show,
      createNewOrder,
    };
  }
};
</script>
<template>

<!--
  Example taken from https://getbootstrap.com/docs/5.0/examples/sidebars/
-->

<div class="d-flex flex-column flex-shrink-0 bg-toolbar vh-100" style="width:4.5rem">

    <a href="/" class="d-flex link-dark text-decoration-none mt-5 mb-4" title="Icon-only" data-bs-toggle="tooltip" data-bs-placement="right">
      <img v-if="state.user.profilePic" :src="state.user.profilePic" style="width:3rem;height:3rem" class="mx-auto rounded-circle border border-primary border-2" />
    </a>

    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center mt-4">

      <li class="nav-item">
        <router-link to="/" class="nav-link py-4 border-bottom" :class="{ active : activeMenu == 'Dashboard' }" aria-current="page" title="Übersicht" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="bi-house fs-5 mx-auto" role="img" aria-label="Dashboard"></i>
        </router-link>
      </li>

      <li v-if="auth.hasRole('Doctor')" class="nav-item">
        <router-link to="/consultations" class="nav-link py-4 border-bottom" :class="{ active : activeMenu == 'Consultations' }" aria-current="page" title="Beratungen" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="bi-person-video3 fs-5 mx-auto" role="img" aria-label="Consultations"></i>
        </router-link>
      </li>

      <li v-if="auth.hasRole('Doctor')" class="nav-item">
        <router-link to="/appointments" class="nav-link py-4 border-bottom" :class="{ active : activeMenu == 'Appointments' }" aria-current="page" title="Termine" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="bi-hourglass-split fs-5 mx-auto" role="img" aria-label="Appointments"></i>
        </router-link>
      </li>

      <li v-if="auth.hasRole('Doctor')" class="nav-item">
        <router-link to="/schedule" class="nav-link py-4 border-bottom" :class="{ active : activeMenu == 'Schedule' }" aria-current="page" title="Sprechstunden Planer" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="bi-calendar2-check fs-5 mx-auto" role="img" aria-label="Schedule"></i>
        </router-link>
      </li>

      <li v-if="auth.hasRole('Admin')" class="nav-item">
        <router-link to="/orders/commission" class="nav-link py-4 border-bottom" :class="{ active : activeMenu == 'Orders' }" aria-current="page" title="Test Kit Bestellungen" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="bi-box-seam fs-5 mx-auto" role="img" aria-label="Orders"></i>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link to="/settings" class="nav-link py-4" :class="{ active : activeMenu == 'Settings' }" aria-current="page" title="Einstellungen" data-bs-toggle="tooltip" data-bs-placement="right">
          <i class="bi-gear fs-5 mx-auto" role="img" aria-label="Settings"></i>
        </router-link>
      </li>

    </ul>

    <div class="dropdown">
      <a href="/" class="d-flex link-dark text-decoration-none mt-3 mb-3" title="Icon-only" data-bs-toggle="tooltip" data-bs-placement="right">
        <i class="bi-power menu-icon fs-5 mx-auto" @click="logout"></i>
      </a>
    </div>

</div>

</template>

<script>

import { reactive } from 'vue';

import auth from '@/modules/auth.js'
import log from '@/modules/logging.js';
import assets from '@/modules/assets.js';

export default {
  name: 'Toolbar',
  props: {
    activeMenu: String
  },
  setup() {

    const state = reactive({
      user: {
        profilePic: assets.logo
      },
    });

    function init() {

/*
      if (localStorage.getItem("user") != null) {
        try {
          state.user = JSON.parse(localStorage.getItem("user"));
        }
        catch (e) {
          log.error("Error reading value 'user' from local storage", e);
        }
      }
      log.trace("Toolbar:init() State User: {}", state.user);
*/

      //
      // fetch current user
      auth.isAuthenticated().then(isAuthenticated => {
        if (isAuthenticated) {
          auth.getUser().then(user => { 
            //
            // set user
            state.user = user;
            log.info("Toolbar:init() User logged in: {}", user);
          });
        }
        else {
          state.user = null;
          log.trace("Toolbar:init() No user is logged in");
        }
      });
    }

    //
    // logout current user
    function logout() {

      log.trace("Sign out user");

      auth.isAuthenticated().then(isAuthenticated => {
        if (isAuthenticated) {
          auth.signOut().then(() => {

            //
            // reset user
            state.user = null;
            // localStorage.removeItem("toolbar.profile");

            log.trace("Toolbar:logout() User signed out");
          });
        }
      });
    }

    function refresh() {
      init();
    }

    init();

    return {
      auth,
      state,
      refresh,
      logout,
    };
  }
}
</script>

<style lang="scss">

@import '../assets/styles/custom-bootstrap.scss';

.bg-toolbar {
  background-color: $gray-200;
}

.menu-icon {
  font-size: 1.5rem; 
  color: lightgray;
}

i.menu-icon:hover {
  color: $cyan;
}

</style>

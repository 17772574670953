<template>
<div class="modal" ref="editSlotsDialog" tabindex="-1" aria-labelledby="editSlotsDialog" aria-hidden="true">
<div class="modal-dialog modal-xl">

  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">Sprechstunden</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="modal-body p-4">
      
      <div class="row bg-light rounded border mb-3 p-2" v-for="template in state.slotTemplates" :key="template">

        <div class="col-4">
          <small class="fw-bold">Von</small>
          <div>{{ $fmt.fromMillisToDateTime(template.startAt) + 'h - ' + $fmt.fromMillisToTime(template.endAt) + 'h'}}</div>
        </div>

        <div class="col-3">
          <div><small class="fw-bold">Wiederholung am</small></div>
          <div v-for="weekday in template.recurringWeekdays" :key="weekday" class="badge bg-secondary rounded me-1">{{ WEEKDAYS[weekday].de }}</div>
          <div v-if="!template.recurringWeekdays" class="badge bg-secondary rounded">Einmalig</div>
        </div>

        <div class="col-3">
          <small class="fw-bold">Wiederholung endet am</small>
          <div>{{ template.recurringEndDate ?? '-' }}</div>
        </div>

        <div class="col-2">
          <button class="btn btn-primary float-end" @click="deleteSlotTemplate(template.uuid)">Löschen</button>
        </div>
      </div>

    </div>
  </div>

</div>
</div>
</template>

<script>

import { reactive, ref } from 'vue'

import { Modal } from 'bootstrap'

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'EditSlots',
  emits: [
    'onUpdated'
  ],
  created() {
    this.WEEKDAYS = [
      { de: "Mo", deLong: "Montag",     value: 0 },
      { de: "Di", deLong: "Dienstag",   value: 1 },
      { de: "Mi", deLong: "Mittwoch",   value: 2 },
      { de: "Do", deLong: "Donnerstag", value: 3 },
      { de: "Fr", deLong: "Freitag",    value: 4 },
      { de: "Sa", deLong: "Samstag",    value: 5 },
      { de: "So", deLong: "Sonntag",    value: 6 }
    ];
  },
  setup(props, { emit }) {

    const editSlotsDialog = ref(null);

    let editSlotsModal = null;
    let lastQueryAt = null;

    const state = reactive({
      slotTemplates: [],
    });

    function show(at) {

      //
      // query slot templates for given point of time
      query(at);

      //
      // show as modal dialog
      editSlotsModal = new Modal(editSlotsDialog.value);
      editSlotsModal.show();
    }

    function query(at) {

      state.slotTemplates = [];

      api.querySlotTemplates(at).then(data => {
          log.trace("EditSlots:query() Fetched Slots Templates: {}", data);
          state.slotTemplates = data;
          if (state.slotTemplates.length == 0) {
            editSlotsModal.hide();
          }
          lastQueryAt = at;
      });
    }

    function deleteSlotTemplate(uuid) {
      api.deleteSlotTemplate(uuid).then(() => {
        emit('onUpdated');
        query(lastQueryAt);
      });
    }

    return {
      editSlotsDialog,
      state,
      show,
      deleteSlotTemplate
    };
  }
};
</script>
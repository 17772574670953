<template>
<Toolbar :activeMenu="'Appointments'"/>
<div class="w-100 overflow-auto">

<!-- Headline -->
<div class="header">

  <div class="container px-5 pt-5">
    <h1 class="display-5">
      <i class="bi-hourglass-split pe-2 text-primary" role="img" aria-label="Appointments"></i>
      Wartebereich
    </h1>
    <span class="lead">Hier finden Sie Ihre nächsten Termine auf einen Blick</span>
  </div>

</div>

<!-- content -->
<div class="container px-5 pb-5">

  <!-- waiting room -->
  <Waitingroom></Waitingroom>

  <!-- upcoming appointments today -->
  <div v-if="state.todayAppointments.length > 0">
    <AppointmentTable :appointments="state.todayAppointments" @onUpdated="queryAppointments()" class="mt-5">
      <div class="bg-light p-3">
        <span class="fs-4 lead">Heute</span>
      </div>
    </AppointmentTable>
  </div>

  <!-- upcoming appoints the next days -->
  <div v-if="state.upcomingAppointments.length > 0">
    <AppointmentTable :appointments="state.upcomingAppointments" @onUpdated="queryAppointments()" class="mt-5">
      <div class="bg-light p-3">
        <span class="fs-4 lead">Demnächst</span>
      </div>
    </AppointmentTable>
  </div>

</div>

</div>
</template>

<script>

import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'

import Toolbar from '@/components/Toolbar.vue'
import Waitingroom from './Waitingroom.vue'
import AppointmentTable from './AppointmentTable.vue'

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'AppointmentList',
  components: {
    Toolbar,
    Waitingroom,
    AppointmentTable,
  },
  setup() {

    const router = useRouter();

    const state = reactive({

      appointments: [],
      todayAppointments: computed(() => {
        return state.appointments.filter(a => new Date(a.slotAt) <= eod());
      }),
      upcomingAppointments: computed(() => {
        return state.appointments.filter(a => new Date(a.slotAt) > eod());
      })
    });

    function eod() {
      let eod = new Date();
      eod.setHours(23);
      eod.setMinutes(59);
      eod.setSeconds(59);
      eod.setMilliseconds(999);
      return eod;
    }

    function queryAppointments() {

      let now = new Date();
      let nextMonth = new Date();
      nextMonth.setDate(nextMonth.getDate() + 30);

      api.queryAppointmentsWithConsultations(now, nextMonth).then(response => {
        log.trace("AppointmentList:queryAppointments() Fetched appoiintments: {}", response);
        state.appointments = response.items;
      });
    }

    function gotoPatient(uuid) {
      router.push({ path: `/patients/${uuid}` });
    }

    queryAppointments();

    return {
      state,
      eod,
      gotoPatient,
      queryAppointments,
    };
  }
};
</script>
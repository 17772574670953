<template>
<div>
  <!-- bubble events -->
  <HealthRecordList :patient="state.patient" @onBusy="$emit('onBusy', $event)" @onUpdated="resolveRoute()"></HealthRecordList>
</div>
</template>

<script>

import { reactive, watch } from 'vue'
import { useRoute } from 'vue-router'

import HealthRecordList from '@/views/patient/HealthRecordList.vue';

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'ConsultationPatient',
  emits: [ 'onBusy', 'onUpdated' ],
  components: {
    HealthRecordList,
  },
  setup(props, { emit }) {

    //
    // keep track of route
    const route = useRoute();
    watch(() => route.path, () => {
      resolveRoute();
    });

    const state = reactive({
      patient: {
        healthRecords: [],
      },
    });

    function resolveRoute() {
      const path = route.path.split("/").filter(s => s.length > 0);
      if (path[0] == 'consultations' && path[1]) {
        query(path[1]);
      }
    }

    function query(uuid) {
      emit('onBusy', true);
      api.getPatientByConsultation(uuid).then(response => {
        log.trace("ConsultationMedicalHistory:query() Fetched patient: {}", response);
        state.patient = response;
        emit('onBusy', false);
      });
    }

    resolveRoute();

    return {
      state,
      resolveRoute,
    };
  }
};
</script>
import { DateTime } from 'luxon'

export default {

    toDate(date) {
        return date != null ? this.fromMillisToDate(date.getTime()) : '';
    },

    toDateTime(date) {
        return date != null ? this.fromMillisToDateTime(date.getTime()) : '';
    },

    toTime(date) {
        return date != null ? this.fromMillisToTime(date.getTime()) : '';
    },

    fromMillisToDate(millis) {
        return DateTime.fromMillis(millis).setLocale("de-de").toLocaleString();
    },

    fromFormatToDate(str, fmt) {
        return str && fmt ? DateTime.fromFormat(str, fmt).setLocale("de-de").toLocaleString() : "";
    },

    fromMillisToDateTime(millis) {
        return DateTime.fromMillis(millis).setLocale("de-de").toLocaleString() + ' ' + DateTime.fromMillis(millis).setLocale("de-de").toLocaleString(DateTime.TIME_SIMPLE);
    },

    fromMillisToTime(millis) {
        return DateTime.fromMillis(millis).setLocale("de-de").toLocaleString(DateTime.TIME_SIMPLE);
    },

    toDuration(fromMs, toMs, withSeconds = false) {

        let durationStr = "";
        let duration = Math.round((toMs - fromMs) / 1000);
  
        //
        // days
        if (duration > 60 * 60 * 24) {
          let days =  Math.floor(duration / (60 * 60 * 24));
          durationStr += `${days} ${days == 1 ? ' Tag' : ' Tagen'}`;
          duration -= days * 60 * 60 * 24;
        }
  
        //
        // hours
        if (duration > 60 * 60) {
          let hours =  Math.floor(duration / (60 * 60));
          duration -= hours * 60 * 60;
  
          if (durationStr.length > 0) {
            durationStr += " ";
          }
          durationStr += `${hours}h`;
        }
  
        //
        // minutes
        if (duration > 60) {
          let minutes  = Math.floor(duration / 60);
          duration -= minutes * 60;
  
          if (durationStr.length > 0) {
            durationStr += " ";
          }
          durationStr += `${minutes}min`;
        }
  
        //
        // seconds
        if (durationStr === "" || (withSeconds && duration != 0)) {
          if (durationStr.length > 0) {
            durationStr += " ";
          }
          durationStr += `${duration}s`;
        }
  
        return durationStr;
      },

      toGender(gender, name) {
        if (gender === 'Male') {
            return (name && name === 'Mark Kirschbaum') ? 'ausgesprochen männlich' : 'männlich';
        }
        if (gender === 'Female') {
            return 'weiblich';
        }
        return 'ohne Angabe';
    },

    toFormat(value, format) {
      return DateTime.fromJSDate(value).toLocaleString(format);
  },
}

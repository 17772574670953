<template>

<div id="scrollbox" class="w-100 overflow-auto">
<div class="container">

  <!-- Not Eligible Dialog -->
  <div class="modal" ref="notEligibleDialog" tabindex="-1" aria-labelledby="newSlotDialogLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">
        <i class="bi bi-exclamation-triangle text-warning me-1"></i>
        Prüfung Ihrer Angaben
      </h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="modal-body">
      <div class="p-2 p-md-4">
        <p class="fs-5">Auf Basis Ihrer Angaben können wir Ihnen aktuell keinen Termin über unser Portal anbieten. Wenn Sie dennoch eine genetische Beratung in Anspruch nehmen möchten, können Sie uns Ihr Anliegen gerne per E-Mail an <a href="mailto:beratung@viagen.de" target="_blank">beratung@viagen.de</a> mitteilen.</p>
      </div>
    </div>

  </div>
  </div>
  </div>

  <!-- Not Available Dialog -->
  <div class="modal" ref="notAvailableDialog" tabindex="-1" aria-labelledby="newSlotDialogLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title">
        <i class="bi bi-exclamation-triangle text-warning me-1"></i>
        Auswahl nicht verfügbar
      </h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

    <div class="modal-body">
      <div class="p-2 p-md-4">
        <p class="fs-5">Zu Ihrer Fragestellung können wir Ihnen aktuell keinen Termin über unser Portal anbieten. Wenn Sie dennoch eine genetische Beratung in Anspruch nehmen möchten, können Sie uns Ihr Anliegen gerne per E-Mail an <a href="mailto:beratung@viagen.de" target="_blank">beratung@viagen.de</a> mitteilen.</p>
      </div>
    </div>

  </div>
  </div>
  </div>

  <!-- Headline -->
  <div v-if="state.page.name === PAGES.Selection.name">
    <img src="@/assets/viagen-logo-h32-black.png" height="32" class="mt-4 mx-auto d-block"/>
    <h1 class="display-5 mt-4 text-center">Buchen Sie Ihre humangenetische Beratung</h1>
  </div>

  <!-- Navigation & Progress Bar-->
  <div class="sticky-top" v-if="state.page.order > PAGES.Selection.order">
    <AppointmentProgress :page="state.page.order - 1"></AppointmentProgress>
  </div>

  <!-- Situation -->
  <div v-if="state.page.name === PAGES.Selection.name" class="border rounded my-4 p-3 p-md-5">


    <div class="row">

      <div class="col-xl-6">
        <h1 class="display-6 fw-bold">Fragestellung</h1>
        <p class="fs-5">Für welche Situation oder Fragestellung wünschen Sie eine humangenetische Beratung?</p>
        <p class="text-muted">Die von Ihnen im Formular digital eingegebenen Daten werden zunächst ausschließlich lokal in Ihrem Browser auf Ihrem Endgerät gespeichert und nicht an uns übertragen. Eine Übertragung Ihrer Daten, einschließlich Gesundheitsdaten an viagen erfolgt erst nach Terminfindung und Buchung.</p>
      </div>

      <div class="col-xl-6">
        <button type="button" class="btn btn-primary rounded mt-2 w-100" @click="start('Cancer')">
          Erblicher Brust- und Eierstockkrebs
        </button>
        <button type="button" class="btn btn-outline-primary rounded mt-3 w-100" @click="showNotAvailable();">
          Reproduktion &amp; Vererbung
        </button>
        <button type="button" class="btn btn-outline-primary rounded mt-3 w-100" @click="showNotAvailable();">
          Arzneimittelunverträglichkeit
        </button>
        <button type="button" class="btn btn-outline-primary rounded mt-3 w-100" @click="showNotAvailable();">
          Pädiatrie
        </button>
        <button type="button" class="btn btn-outline-primary rounded mt-3 w-100" @click="showNotAvailable();">
          Hez-Kreislauf Erkankungen
        </button>
        <button type="button" class="btn btn-outline-primary rounded mt-3 w-100" @click="showNotAvailable();">
          Seltene Erkrankungen
        </button>
      </div>
    </div>
  </div>

  <!-- Medical History -->
  <div v-if="state.page.name == PAGES.MedicalHistory.name" class="border rounded my-4 p-3 p-md-5">
    <QuestionaireDiabetes :medicalHistory="state.medicalHistory" v-if="state.questionaire == 'Diabetes'" @onNext="onQuestionaireFinished($event)" @onNotEligible="showNotEligible()"></QuestionaireDiabetes>
    <QuestionaireCancer :medicalHistory="state.medicalHistory" v-if="state.questionaire == 'Cancer'" @onNext="onQuestionaireFinished($event)" @onNotEligible="showNotEligible()"></QuestionaireCancer>
  </div>

  <!-- Calendar -->
  <div v-if="state.page.name == PAGES.Appointment.name" class="border rounded my-4 p-4 p-md-5">

    <div class="row">
      <div class="col-auto">
        <button type="button" class="btn btn-outline-primary rounded mb-5" @click="goto(PAGES.MedicalHistory)">Zurück</button>
        <h1 class="display-6 fw-bold">Termin finden</h1>
        <p class="fs-5">Suchen Sie einen freien Termin aus dem Kalendar aus und wählen Sie eine Uhrzeit. Bitte nehmen Sie sich für den Termin ca. 60 Minuten Zeit</p>
      </div>
    </div>

    <Calendar :bookedAt="state.bookedAtDateTime" :shortWeek="true" @onSlotSelected="onSlotSelected($event)"></Calendar>

    <!-- Navigation -->
    <div class="d-flex justify-content-end mt-3">
      <button type="button" class="btn btn-primary rounded ms-auto" @click="goto(PAGES.Contact)" :disabled="!state.bookedAtDateTime">Weiter zu Kontakt</button>
    </div>

  </div>

  <!-- Contact -->
  <div v-if="state.page.name == PAGES.Contact.name" class="border rounded my-4 p-4 p-md-5">

    <div class="row">

      <div class="col-xl-6">
        <button type="button" class="btn btn-outline-primary rounded mb-5" @click="goto(PAGES.Appointment)">Zurück</button>
        <h1 class="display-6 fw-bold">Kontaktdaten</h1>
        <p class="fs-5">Bitte teilen Sie uns die folgenen persönlichen Angaben mit.</p>
        <p class="text-muted">Wir benötigen Ihre E-Mail Adresse, um Ihnen einen Bestätigungscode und den Link für die Videokonsultation zukommen zu lassen. Bitte hinterlegen Sie auch Ihre mobile Telefonnummer, so dass wir Sie bei möglichen Rückfragen telefonisch gut erreichen können.</p>
      </div>

      <div class="col-xl-6">
        
        <form class="needs-validation" :class="{ 'was-validated' : !state.contact.valid }" novalidate>

          <label for="inputName" class="form-label">Anrede</label>
          <div class="input-group">
            <div class="form-check form-check-inline">
              <input v-model="state.contact.gender" class="form-check-input" type="radio" name="genderOptions" id="radioMale" value="Male" required>
              <label class="form-check-label" for="radioMale">Herr</label>
            </div>
            <div class="form-check form-check-inline">
              <input v-model="state.contact.gender" class="form-check-input" type="radio" name="genderOptions" id="radioFemale" value="Female" required>
              <label class="form-check-label" for="radioFemale">Frau</label>
            </div>
            <div class="form-check form-check-inline mb-3">
              <input v-model="state.contact.gender" class="form-check-input" type="radio" name="genderOptions" id="radioDivers" value="Diverse" required>
              <label class="form-check-label" for="radioDivers">Divers</label>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6 mb-3">
              <label for="inputName" class="form-label">Vorname</label>
              <input v-model="state.contact.name" type="text" class="form-control" id="inputName" placeholder="Ihr Vorname" required>
              <div class="invalid-feedback">Bitte geben Sie Ihren Vornamen an</div>
            </div>
            <div class="col-xl-6 mb-3">
              <label for="inputName" class="form-label">Nachname</label>
              <input v-model="state.contact.familyName" type="text" class="form-control" id="inputFamilyName" placeholder="Ihr Nachname" required>
              <div class="invalid-feedback">Bitte geben Sie Ihren Nachnamen an</div>
            </div>
          </div>

          <div class="mb-3">
            <label for="inputEmail" class="form-label">E-Mail</label>
            <input v-model="state.contact.email" type="email" class="form-control" :class="{ 'is-invalid' : !state.contact.emailValid }" id="inputEmail" placeholder="Ihre E-Mail Adresse" required>
            <div class="invalid-feedback">Bitte geben Sie eine gültige E-Mail Adresse an</div>
          </div>

          <div>
            <label for="inputMobileNumber" class="form-label">Mobile Telefonnummer</label>
            <input v-model="state.contact.mobileNumber" type="text" class="form-control" :class="{ 'is-invalid' : !state.contact.mobileNumberValid }" id="inputMobileNumber" placeholder="Ihre mobile Telefonnummer" required>
            <div class="invalid-feedback">Bitte geben Sie eine gültige <b>mobile</b> Telefonnummer an</div>
          </div>

          <!-- Navigation -->
          <div class="d-flex justify-content-end mt-4">
            <button type="button" class="btn btn-primary rounded"  @click="goto(PAGES.Review, $event)">Weiter zur Überprüfung</button>
          </div>

        </form>

      </div>
    </div>
  </div>

  <!-- Review -->
  <div v-if="state.page.name == PAGES.Review.name" class="border rounded my-4 p-4 p-md-5">

    <div class="row g-5">

      <div class="col-xl-6 align-items-start">
        
        <button type="button" class="btn btn-outline-primary rounded mb-5" @click="goto(PAGES.Contact)" :disabled="state.busy">Zurück</button>

        <div class="d-flex">
          <i class="bi-file-medical fs-3 me-3" role="img" aria-label="HealthHistory"></i>
          <div class="mt-1">
            <h3>Krankengeschichte</h3>

            <ul v-if="state.questionaire == 'Diabetes'">
              <li>Ihre Diabetes wurde im Alter von {{ state.medicalHistory.ageDiagnosis }} Jahren diagostiziert.</li>
              <li>Sie sind {{ state.medicalHistory.height }}cm groß und wiegen {{ state.medicalHistory.weight }}kg. Daraus ergbit sich ein BMI von ~{{ Math.round(state.medicalHistory.bmi) }}.</li>
            </ul>

            <ul v-if="state.questionaire == 'Cancer'">
              <li v-if="getMedicalHistoryValue('Questionaire', 'Cancer', 'cancerDiagnosis') === 'true'">Bei Ihnen wurde Brust- oder Eierstockkrebs diagnostiziert.</li>
              <li v-if="getMedicalHistoryValue('Questionaire', 'Cancer', 'otherCancerDiagnosis') === 'true'">Sie hatten nach oder vor Ihrer Brust- bzw. Eierstockkrebserkrankung eine davon unabhängige Krebserkrankung (z.B. Hautkrebs).</li>
              <li v-if="getMedicalHistoryValue('Questionaire', 'Cancer', 'ageDiagnosisBefore51') === 'true'">Ihr Brust- oder Eierstockkrebs wurde vor dem 51 Jahr diagnostiziert.</li>
              <li v-if="getMedicalHistoryValue('Questionaire', 'Cancer', 'cancerInFamiliy') === 'true'">In Ihrer Blutsverwandschaft sind Personen bekannt, bei denen Krebs festgestellt wurde.</li>
              <li v-if="getMedicalHistoryValue('Questionaire', 'Cancer', 'tripleNegativeCancer') === 'true'">Bei Ihnen wurde ein triple-negativer Krebs festgestellt.</li>
            </ul>
          </div>
        </div>

        <div class="d-flex mt-4">
          <i class="bi-calendar2-check fs-3 me-3" role="img" aria-label="Termin"></i>
          <div class="mt-1">
            <h3>Beratungstermin</h3>
            <p class="fs-5">Sie haben den <b>{{ $fmt.toDateTime(new Date(state.bookedAtDateTime)) }}h</b> ausgewählt</p>
          </div>
        </div>

        <div class="d-flex mt-4">
          <i class="bi-person-lines-fill fs-3 me-3" role="img" aria-label="Kontakt"></i>
          <div class="mt-1">
            <h3>Kontakt</h3>
            <p class="fs-5">{{ toTitle(state.contact.gender) + ' ' + state.contact.name + ' ' + state.contact.familyName }}</p>
            <p><i class="bi bi-envelope me-2"></i>{{ state.contact.email }}</p>
            <p><i class="bi bi-phone me-2"></i>{{ state.contact.mobileNumber }}</p>
          </div>
        </div>

      </div>

      <div class="col-xl-6">

        <h1 class="display-6 fw-bold">Termin prüfen</h1>
        <p class="fs-5">Bitte prüfen Sie Ihre Angaben und lesen und akzeptieren Sie die Nutzungsbedingungen und Datenschutzerklärung. Bitte beachten Sie, dass viagen selbst keine ärztlichen Leistungen erbringt und kein Gesundheitsdienstleister ist. Ein Behandlungsvertrag kommt ausschließlich zwischen Ihnen und dem behandelnden Arzt zustande.</p>

        <div class="form-check mt-4">
          <input class="form-check-input" type="checkbox" v-model="state.review.dataIsCorrect" value="true" id="termsOfUse">
          <label class="form-check-label" for="termsOfUse">
            <b>Ja</b>, ich habe die viagen <a href="https://www.viagen.de/nutzungsbedingungen" target="_blank">Nutzungsbedingungen <i class="bi-box-arrow-up-right"></i></a> gelesen und bestätige, dass ich unbeschränkt geschäftsfähig, sowie in Deutschland wohnhaft und krankenversichert bin.
          </label>
        </div>
        <div class="form-check mt-2">
          <input class="form-check-input" type="checkbox" v-model="state.review.acceptPrivacyStatement" value="true" id="dataPrivacy">
          <label class="form-check-label" for="dataPrivacy">
            <b>Ja</b>, ich willige darin ein, dass viagen und der mich behandelnde Arzt (gemeinsam Verantwortliche) meine personenbezogenen Daten, einschließlich meiner Gesundheitsdaten ausschließlich zum Zwecke der Buchung und Durchführung des Beratungstermins und ggf. bei entsprechender Indikation zum Zwecke einer Laboranalyse und digitalen Bereitstellung eines Befunds verarbeiten. Ich kann meine Einwilligung jederzeit gegenüber viagen widerrufen, z.B. per E-Mail an <a href="mailto:datenschutz@viagen.de" target="_blank">datenschutz@viagen.de</a>. Einzelheiten zur Verarbeitung von personenbezogenen Daten einschl. Gesundheitsdaten finden Sie in unserer <a href="https://www.viagen.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung <i class="bi-box-arrow-up-right"></i></a>.
          </label>
        </div>

        <div v-if="state.error" class="border p-4 my-4 rounded bg-warning">
          <h5>{{ state.error }}</h5>
        </div>

        <div class="d-flex justify-content-center mt-4">
          <button type="button" class="btn btn-primary rounded" @click="bookAppointment()" :disabled="!(state.review.dataIsCorrect && state.review.acceptPrivacyStatement) || state.busy">
            <span v-if="state.busy" class="spinner-border spinner-border-sm text-light me-2"></span>
            Termin verbindlich buchen 
          </button>
        </div>

      </div>

    </div>
  </div>

  <!--footer -->
  <div class="text-center mb-4">
    <a href="https://www.viagen.de/impressum" target="_blank">Impressum</a> |
    <a href="https://www.viagen.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> |
    <a href="https://www.viagen.de/nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a>
  </div>
 
</div>
</div>

</template>

<script>

import { ref, reactive, watch, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { Modal } from 'bootstrap'

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

import Calendar from './Calendar.vue';
import QuestionaireDiabetes from './QuestionaireDiabetes.vue';
import QuestionaireCancer from './QuestionaireCancer.vue';
import AppointmentProgress from './AppointmentProgress.vue';

import parsePhoneNumber from 'libphonenumber-js/mobile';

export default {
  name: 'NewAppointment',
  components: {
    Calendar,
    AppointmentProgress,
    QuestionaireDiabetes,
    QuestionaireCancer
  },
  setup() {

    const PAGES = { 
      Selection:      { order: 0, name: ''},
      MedicalHistory: { order: 1, name: '#Krankengeschichte'},
      Appointment:    { order: 2, name: '#Termin'},
      Contact:        { order: 3, name: '#Kontakt'},
      Review:         { order: 4, name: '#Uebersicht'},
      Confirmation:   { order: 5, name: '#Bestaetigung'}, // is this necessary? -> I do not think so
    };

    const tracking = inject("tracking");
    const route = useRoute();
    watch(() => route.hash, () => {
      resolveRoute();
    });
    const router = useRouter();

    const notEligibleDialog = ref(null);
    const notAvailableDialog = ref(null);

    const state = reactive({
      page : null,
      busy : null,
      error : null,
      questionaire : null,
      medicalHistory : null,
      bookedAtDateTime : null,
      contact : null,
      review : null
    });

    function initState() {

      /**
       * Active page
       */
      state.page = PAGES.Selection;

      /**
       * State
       */
      state.busy = false;
      state.error = null;

      /**
       * Selected questionaire
       */
      state.questionaire = null;

      /**
       * Medical History
       */
      state.medicalHistory = null;

      /**
       * Calender
       */
      state.bookedAtDateTime = null;

      /**
       * Contact
       */
      state.contact = {

        valid: true,

        gender: null,
        name: null,
        familyName: null,
        personValid: true,

        email: null,
        emailValid: true,

        mobileNumber: null,
        mobileNumberValid: true,
      };

      /**
       * Review
       */
      state.review = {
        dataIsCorrect: false,
        acceptPrivacyStatement: false,
      };
    }


    /**
     * Start process with given questionaire
     */
    function start(questionnaire) {
      state.questionaire = questionnaire;
      goto(PAGES.MedicalHistory);
    }


    /**
     * Callback of questionaire component
     */
    function onQuestionaireFinished(questionaire) {
      state.medicalHistory = questionaire;
      goto(PAGES.Appointment);
    }


    /**
     * Request to go to the given page - I think next() would be a better choice as a function and name
     */
    function goto(page) {

      log.trace("NewAppointment:goto() Goto page: {}", page);

      //
      // if we are going from contact to review - we will do a contact validation
      if (state.page.name == PAGES.Contact.name && page.name == PAGES.Review.name) {
        if (!validateContact()) {
          // we not go forward from here
          return;
        }
      }

      //
      // set the hash
      router.push({ hash: page.name});
    }


    function resolveRoute() {

      //
      // get the last possible 'valid' page
      let maxPage = PAGES.Selection;
      if (isQuestionnaireSelected()) {
        maxPage = PAGES.MedicalHistory;
      }
      if (isMedicalHistoryValid()) {
        maxPage = PAGES.Appointment;
      }
      if (isSlotBooked()) {
        maxPage = PAGES.Contact;
      }
      if (isContactValid()) {
        maxPage = PAGES.Review;
      }
      log.trace("NewAppointment:resolveRoute() Last validated page is: '{}'", maxPage.name);

      //
      // get requested page based on hash
      const requestedPage = mapHashToPage(route.hash);
      log.trace("NewAppointment:resolveRoute() Requested page is '{}'", requestedPage);

      //
      // track state
      if (requestedPage === PAGES.Selection) {
        tracking.trackEvent('BookAppointment', 'Booking started');
      }

      //
      // if requested page is not avialble yet 
      // (in case it looks like some steps were skipped manually via browser URL)
      // we will redirect back to the latest valid page
      if (requestedPage.order > maxPage.order) {

        //
        // trigger lifecycle hook for page
        onBeforeEnter(maxPage);

        //
        // replace hash with with last validated page 
        router.replace({ hash: maxPage.name});

        return;
      }

      //
      // trigger lifecycle hook for page
      onBeforeEnter(requestedPage);

      //
      // goto the requested page
      state.page = requestedPage;
    }


    function mapHashToPage(hash) {
      for (var page of Object.values(PAGES)) {
        if (page.name == hash) {
          return page;
        }
      }
      return PAGES.Selection;
    }


    function onBeforeEnter(requestedPage) {
        //
        // reset review page opt-ins before entering it
        if (requestedPage == PAGES.Review) {
          state.review.dataIsCorrect = false;
          state.review.acceptPrivacyStatement = false;
          state.error = null;
        }
    }

    function isQuestionnaireSelected() {
      return state.questionaire != null;
    }

    function isMedicalHistoryValid() {
      return state.medicalHistory != null;
    }

    function isSlotBooked() {
      return state.bookedAtDateTime != null;
    }

    function isContactValid() {

      if (!isValidContactInfo()) {
        return false;
      }

      if (!isValidEmail()) {
        return false;
      }

      if (!isValidPhoneNumber()) {
        return false;
      }

      return true;
    }

    function validateContact() {

      state.contact.personValid = isValidContactInfo();
      state.contact.emailValid = isValidEmail();
      state.contact.mobileNumberValid = isValidPhoneNumber();

      state.contact.valid = 
        state.contact.personValid && 
        state.contact.emailValid && 
        state.contact.mobileNumberValid;

      log.trace("Validated Contact {}", state);
      return state.contact.valid;
    }

    function isValidContactInfo() {
      return (state.contact.gender != null) && 
        (state.contact.name != null && state.contact.name.length > 0) &&
        (state.contact.familyName != null && state.contact.familyName.length > 0);
    }

    function isValidEmail() {

      if (!(state.contact.email != null && state.contact.email.length > 0)) {
        return false;
      }

      return String(state.contact.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    function isValidPhoneNumber() {

      if (!(state.contact.mobileNumber != null && state.contact.mobileNumber.length > 0)) {
        return false;
      }

      const phoneNumber = parsePhoneNumber(state.contact.mobileNumber, 'DE');
      if (phoneNumber.isValid()) {
        state.contact.mobileNumber = phoneNumber.number;
        return true;
      }
      return false;
    }

    function toTitle(gender) {
      switch (gender) {
        case "Male": return "Herr";
        case "Female": return "Frau"
        default: return "";
      }
    }

    function onSlotSelected(bookedAt) {
      state.bookedAtDateTime = bookedAt;
    }

    function getMedicalHistoryValue(type, subType, key) {
      let questionaire = state.medicalHistory.find(e => e.type === type && e.subType === subType);
      if (questionaire) {
        let entry = questionaire.values.find(e => e.name === key);
        if (entry) {
          return entry.value;
        }
      }
      return null;
    }

    function bookAppointment() {

      state.busy = true;
      state.error = null;

      api.createAppointment({
        'medicalHistory': state.medicalHistory,
        'contact': state.contact,
        'slotAt': state.bookedAtDateTime.getTime()
      }).then(response => {
        state.busy = false;
        log.trace("Create Apointment Response {}", response);
        tracking.trackEvent('BookAppointment', 'Appointment booked');
        router.push({ path: `/appointments/${response.uuid}` });
      }).catch(error => {

        state.busy = false;
        state.review.dataIsCorrect = false;
        state.review.acceptPrivacyStatement = false;

        let e = error.response.data;
        tracking.trackEvent('BookAppointment', 'Error booking appointment: ' + e.errorCode);

        //
        // map error code to text
        if (e.errorCode === "UnfinishedConsultation") {
          state.error = "Sie haben bereits eine Beratung gebucht, die noch nicht abgeschlossen ist. Bitte schließen Sie zunächst die laufende Beratung ab, bevor Sie eine neue buchen.";
        }
        else if (e.erroCode === "SlotNoLongerAvailable") {
          state.error = "Der von Ihnen gewählte Buchungstermin wurde in der Zwischenzeit leider vergeben. Bitte suchen Sie sich einen neuen Termin aus."
        }
        else {
          state.error = "Bei der Buchung ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.";
        }

        log.trace("Create Apointment Error {}", error);
      });
    }

    function showNotEligible() {
        tracking.trackEvent('BookAppointment', 'Not Eligable');
        new Modal(notEligibleDialog.value).show();
        return false;
    }

    function showNotAvailable() {
        tracking.trackEvent('BookAppointment', 'Not Available');
        new Modal(notAvailableDialog.value).show();
        return false;
    }

    initState();

    resolveRoute();

    return {
      PAGES,
      notEligibleDialog,
      notAvailableDialog,
      state,
      onQuestionaireFinished,
      showNotEligible,
      showNotAvailable,
      start,
      goto,
      toTitle,
      onSlotSelected,
      getMedicalHistoryValue,
      bookAppointment,
    };
  }
};
</script>

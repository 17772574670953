<template>
<div class="slider">
  <div class="line"></div>
  <div class="subline" :class="{ 'inc' : state.busy }"></div>
  <div class="subline" :class="{ 'dec' : state.busy }"></div>
</div>
</template>

<script>

import { reactive, watch } from 'vue'

export default {
  name: 'BusyIndicator',
  props: {
    busy: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {

    let busyAt = null;

    watch(() => props.busy, (newBusy) => {
      if (newBusy == true) {
        // start beeing busy immediately
        state.busy = true;
        busyAt = Date.now();
      }
      else {
        // stop beeing busy not sooner then 2.5sec before started beeing busy just to prevent flickering
        let busySince = Date.now() - busyAt;
        setTimeout(function(){ state.busy = newBusy }, busySince > 1200 ? 0 : 1200 - busySince);
      }
    });

    const state = reactive({
      busy: props.busy
    });

    return {
      state
    };
  }
};

</script>

<style lang="scss" scoped>

@import 'bootstrap/scss/bootstrap';

.slider {
  position: absolute;
  height: 4px;
  width: 100%;
  overflow-x: hidden;
}

.line {
  position: absolute;
  background: $gray-200;
  opacity: 0.4;
  width: 100%;
  height: 4px;
}

.subline {
  position:absolute;
  background:#227c9d;
  height:4px; 
}

.inc {
  animation: increase 1.2s infinite;
}

.dec {
  animation: decrease 1.2s 0.3s infinite;
}

@keyframes increase {
 from { left: -5%; width: 5%; }
 to { left: 100%; width: 100%;}
}

@keyframes decrease {
 from { left: -80%; width: 80%; }
 to { left: 100%; width: 10%;}
}

</style>
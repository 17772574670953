<template>

<Toolbar :activeMenu="'Consultations'"/>

<div class="w-100 overflow-auto">

<div class="header">

  <div class="container px-5 pt-5">

    <!-- Headline -->
    <h1 class="display-5">
      <i class="bi-person-video3 pe-2 text-primary" role="img" aria-label="Appointments"></i>
      Beratungsfälle
    </h1>
    <span class="lead">Hier finden Sie alle gebuchten Beratungsfälle mit aktuellem Status auf einen Blick</span>

  </div>
</div>

<div class="container px-5 pb-5">

  <ConsultationTable class="mt-5">
    <div class="bg-light p-3 fs-5">
      Beratungsfälle
    </div>
  </ConsultationTable>

</div>
</div>
</template>

<script>

import { reactive } from 'vue'

import Toolbar from '@/components/Toolbar.vue'
import ConsultationTable from '@/views/consultation/ConsultationTable.vue'

export default {
  name: 'ConsultationList',
  components: {
     Toolbar,
     ConsultationTable
  },
  setup() {

    const state = reactive({
    });

    return {
      state,
    };
  }
};
</script>

<style scoped>
</style>
<template>
<div id="scrollbox" class="w-100 overflow-auto">
<div class="container">

<!-- Headline -->
<img src="@/assets/viagen-logo-h32-black.png" height="32" class="mt-4 mx-auto d-block"/>

<!-- Confirm Email -->
<div v-if="showEmailConfirmation()" class="border rounded mt-4 p-4 p-md-5">

  <div class="text-center">
      <i class="display-1 text-primary bi-envelope-check"></i>
      <h1 class="display-6 fw-bold mt-4">Bitte bestätigen Sie Ihre E-Mail Adresse</h1>
      <div class="lead mt-4">
          Wir haben Ihnen gerade Ihren persönlichen <b>Bestätigungscode</b> an <b>{{ state.appointment.email }}</b> gesendet.
          Bitte geben Sie den Code unten ein und bestätigen sie ihn. Ihre humangenetische Beratung wird erst mit der Bestätigung Ihrer Email von uns verbindlich bestätigt.
      </div>
  </div>

  <div ref="emailConfirmation" v-if="!noMoreConfirmationTrials()">

    <div v-if="!state.appointment.emailConfirmed && state.appointment.remainingConfirmationTrials < state.appointment.maxConfirmationTrials" class="mt-5 text-muted text-center">
      Sie haben noch {{ state.appointment.remainingConfirmationTrials }} Versuche.
    </div>

    <div class="d-flex justify-content-center display-5 mt-5">
      <input type="text" v-model="state.confirmation.code1" class="form-control-code" minlength="1" maxlength="1" @keyup="onEnterCode($event)" onfocus="this.select();" onmouseup="return false;"> 
      <input type="text" v-model="state.confirmation.code2" class="form-control-code mx-2" minlength="1" maxlength="1" @keyup="onEnterCode($event)" onfocus="this.select();" onmouseup="return false;">
      <input type="text" v-model="state.confirmation.code3" class="form-control-code" minlength="1" maxlength="1" @keyup="onEnterCode($event)" onfocus="this.select();" onmouseup="return false;">
      <span class="mx-2">-</span>
      <input type="text" v-model="state.confirmation.code4" class="form-control-code" minlength="1" maxlength="1" @keyup="onEnterCode($event)" onfocus="this.select();" onmouseup="return false;">
      <input type="text" v-model="state.confirmation.code5" class="form-control-code mx-2" minlength="1" maxlength="1" @keyup="onEnterCode($event)" onfocus="this.select();" onmouseup="return false;">
      <input type="text" v-model="state.confirmation.code6" class="form-control-code" minlength="1" maxlength="1" @keyup="onEnterCode($event)" onfocus="this.select();" onmouseup="return false;">
    </div>

    <div v-if="state.confirmation.error == true" class="rounded bg-warning mt-5 p-4 text-white text-center">
        <i class="bi-exclamation-triangle me-2"></i>Der eingegebene Code ist falsch. Bitte geben Sie den Code aus unserer Bestätigungsemail korrekt ein.
    </div>

    <div class="lead text-center mt-4 mb-5">
        <button class="btn btn-primary mt-4" :class="{disabled: !getCode() || noMoreConfirmationTrials() || state.busy }" @click="confirmEmail()">
          <span v-if="state.busy" class="spinner-border spinner-border-sm text-light me-2"></span>
          Bestätigen
        </button>
    </div>

  </div>

  <div v-if="noMoreConfirmationTrials()" class="rounded bg-warning mt-5 p-4 text-white text-center">
      <i class="bi-exclamation-triangle me-2"></i>Sie haben den Code drei Mal falsch eingegeben. Der Termin kann nicht bestätigt werden. Bitten wenden Sie sich für Hilfe an support@viagen.
  </div>

  <hr/>

  <div class="text-muted">
    Bitte beachten Sie: Es kann bis zu 5 Minuten dauern, bis unsere Email bei Ihnen ankommt. Falls Sie unsere Email nicht in Ihrem Posteingang finden könnne, prüfen Sie bitte auch Ihren Spam Ordner. Bitte beachten Sie, dass der Code nur 24h gültig ist und wir Ihre Buchung automatisch stornieren, wenn Sie Ihre Email nicht per Code bestätigen.
  </div>

</div>

<!-- Appointment Confirmed -->
<div v-if="showAppointment()" class="border rounded my-4 p-4 p-md-5">

  <div class="text-center">
      <i class="display-1 text-primary bi-calendar2-check"></i>
      <h1 class="display-6 fw-bold mt-4">Ihre humangenetische Beratung</h1>
      <div class="lead mt-4">
        Vielen Dank für Ihr Vertrauen. Wir haben eine Bestätigung dieser Buchung an Ihre E-Mail Adresse gesendet. Darin finden Sie auch einen Link mit dem Sie wieder auf diese Seite zurückfinden.
      </div>
  </div>

  <div class="row my-5">
  
    <div class="col-md-6 text-center bg-light rounded">

      <div class="p-4">
        <div class="fs-6 mt-2">Ihr Termin</div>
        <div class="fs-5 mt-1 fw-bold">
          <i class="bi-calendar2-check me-2 text-primary"></i>
          {{ $fmt.fromMillisToDateTime(state.appointment.slotAt) }}
        </div>
        <div class="fs-4 my-4">{{ state.waitingTime }}</div>

        <!-- Surrounding DIV to avoid flickering as long as the state is not determined -->
        <div v-if="state.dialIn != null"> 

          <a class="btn btn-primary" :href="state.meetingUrl" target="_blank" :class="{ disabled: !state.dialIn }">
            <i class="bi bi-telephone me-2"></i>
            Einwählen
          </a>
          <div v-if="!state.dialIn">
            <hr/>
            <div class="text-muted mt-2">Der Einwahl Knopf wird 5 Minuten vor dem Termin freigeschaltet.</div>
          </div>

        </div>

      </div>
    </div>

    <div class="col-md-6 text-center">

      <div class="p-4">
        <div class="fs-6 mt-2">Ihr Arzt</div>
        <div class="fs-5 fw-bold mt-1">{{ state.appointment.professional.name }}</div>
        <img :src="getProfilePicture()" style="width:5rem;height:5rem" class="mx-auto rounded-circle border border-primary border-2  mt-3"/>
        <div class="fst-italic mt-3">"{{ state.appointment.professional.profile }}"</div>
      </div>

    </div>

  </div>

  <span class="fs-4">
    <i class="bi-exclamation-diamond text-primary me-2"/>
    Bitte beachten Sie außerdem folgende Ratschläge
  </span>
  <hr/>
  <div class="">
    <ul>
      <li class="mt-2">Bitte halten Sie Ihre Krankenkassenkarte bereit.</li>
      <li class="mt-2">Bitte stellen Sie sicher, dass Sie über eine stabile Internetverbindung verfügen.</li>
      <li class="mt-2">Bitte stellen Sie sicher, dass Ihr Zugangsgerät über eine funktionierende Videokamera und ein Mikrofon verfügt.</li>
      <li class="mt-2">Falls Sie technische Probleme mit der Einwahl per Video haben, wird sich der Arzt telefonisch bei Ihnen melden.</li>
      <li class="mt-2">Bitte stellen Sie sicher, dass Sie das Beratungsgespräch in einer ruhigen und hellen Umgebung führen können.</li>
      <li class="mt-2">Falls Sie die deutsche Sprache nicht gut beherrschen, bitten Sie eine Person ihres Vertrauens dazu, die Sie bei der Verständigung unterstützen kann.</li>
    </ul>
  </div>

  <div class="fs-4 mt-4">
    <i class="bi-pencil text-primary me-2"/>
    Sie möchten Ihren Termin ändern?
  </div>
  <hr/>
  <div>
    Bitte senden Sie uns eine formlose E-Mail an <a :href="'mailto:termin@viagen.de?subject=Terminänderung%20für%20' + state.appointment.uuid">termin@viagen.de</a>, wenn Sie Ihren Termin stornieren oder verschieben wollen.
  </div>

</div>

<!-- Appointment Confirmed -->
<div v-if="isCallEstablished()" class="border rounded my-4 p-4 p-md-5">

  <div class="text-center">
      <i class="display-1 text-primary bi-check-circle-fill"></i>
      <h1 class="display-6 fw-bold mt-4">Ihre humangenetische Beratung</h1>
      <div class="lead mt-4">
        Ihre humangenetische Beratung wurde durchgeführt. Wenn Ihnen der Arzt eine Indikation bestätigen konnte, dann werden Sie in den nächsten Tagen eine Entnahmesystem für eine Speichelprobe zugesandt bekommen.
      </div>
  </div>

</div>

<!-- Footer -->
<div class="text-center mb-5">
    <a href="https://www.viagen.de/impressum" target="_blank">Impressum</a> |
    <a href="https://www.viagen.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> |
    <a href="https://www.viagen.de/nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a>
</div>

</div>
</div>
</template>

<script>

import { reactive, watch, computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import api from '@/modules/api.js';
import fmt from '@/modules/format.js'
import log from '@/modules/logging.js'

export default {
  name: 'Appointment',
  components: {
  },
  setup() {

    //
    // keep track of route
    const route = useRoute();
    watch(() => route.path, () => {
      resolveRoute();
    });

    function resolveRoute() {
      state.busy = true;
      const path = route.path.split("/").filter(s => s.length > 0);
      api.getAppointments(path[1]).then(response => {
        
        log.trace("Appointment:resolveRoute() Get Appointment: {}", response);
        state.appointment = response;
        setupCallStatus();
        state.busy = false;

        //
        // if email is not confirmed yet and code is given in URL - try immediately to confirm it
        if (showEmailConfirmation() && route.query.code && route.query.code.length == 7) {

          log.trace("Appointment:resolveRoute() Code: {}", route.query.code);

          //
          // transfer code to state 
          state.confirmation.code1 = route.query.code.charAt(0);
          state.confirmation.code2 = route.query.code.charAt(1);
          state.confirmation.code3 = route.query.code.charAt(2);
          state.confirmation.code4 = route.query.code.charAt(4);
          state.confirmation.code5 = route.query.code.charAt(5);
          state.confirmation.code6 = route.query.code.charAt(6);
          
          //
          // confirm email
          confirmEmail();
        }
      });
    }

    const emailConfirmation = ref(null);

    const state = reactive({

      busy: false,

      confirmation: {
        code1 : '',
        code2 : '',
        code3 : '',
        code4 : '',
        code5 : '',
        code6 : '',
        error: false
      },

      appointment: {
        uuid: null,
        slotAt: 0,
        email: '',
        emailConfirmed: true,
        remainingConfirmationTrials: 3,
        maxConfirmationTrials: 3,
        professional: {
          uuid: null,
          name: '',
          profile: '',
        }
      },

      meetingUrl: computed(() => { return `${process.env.VUE_APP_API_ENDPOINT}/public/v1/appointments/${state.appointment.uuid}/meeting` }),

      interval: null,
      waitingTime: null,
      dialIn: null,
    });

    function getCode() {

      let code = state.confirmation.code1 + state.confirmation.code2 + state.confirmation.code3 + "-" + 
        state.confirmation.code4 + state.confirmation.code5 + state.confirmation.code6;

      if (code.length == 7) {
        return code;
      }

      return null;
    }

    function confirmEmail() {
      state.busy = true;
      api.confirmEmail(state.appointment.uuid, getCode()).then(response => {
        state.appointment = response;
        state.confirmation.error = !state.appointment.emailConfirmed;
        state.busy = false;
        setupCallStatus();
      });
    }

    function setupCallStatus() {
      if (showAppointment()) {
        state.interval = setInterval(() => {
            if ((new Date().getTime() + (5 * 60 * 1000)) < state.appointment.slotAt) {
              state.waitingTime = "in " + fmt.toDuration(new Date().getTime(), state.appointment.slotAt, true);
            }
            else {
              state.waitingTime = "Bitte wählen Sie sich ein";
              clearInterval(state.interval);
              state.interval = null;
            }
            state.dialIn = (new Date().getTime() + (5 * 60 * 1000)) > state.appointment.slotAt;
        }, 1000);
      }
    }

    function noMoreConfirmationTrials() {
      return !state.appointment.emailConfirmed && state.appointment.remainingConfirmationTrials == 0
    }

    function showAppointment() {
      return state.appointment.emailConfirmed && state.appointment.status != 'CallEstablished';
    }

    function isCallEstablished() {
      return state.appointment.status == 'CallEstablished';
    }

    function showEmailConfirmation() {
      return !state.appointment.emailConfirmed;
    }

    function getProfilePicture() {
      if (state.appointment.professional.uuid) {
        return `${process.env.VUE_APP_API_ENDPOINT}/public/v1/assets?key=${state.appointment.professional.profilePic}`;
      }
      else {
        return "";
      }
    }

    function onEnterCode(event) {
    
      //
      // this is not completely true - as Upper/Lower case and Special Chars on numbers has same key code
      let isCode = /^[a-z0-9]$/i.test(event.key);
      log.trace("Key pressed {} -> Is Code: {}", event.key, isCode);

      if (isCode) {

        let focusable = [...emailConfirmation.value.querySelectorAll("input,a,button")];
        let next = focusable.findIndex(e => e == event.target);
        if (next >= 0) {
          focusable[next + 1].focus();
          return false;
        }

        log.trace("No next focussable element found");
      }
      else {
        log.trace("Input is not a valid code");
        return true;
      }
      return false;
    }

    resolveRoute();

    return {
      emailConfirmation,
      state,
      getCode,
      noMoreConfirmationTrials,
      showEmailConfirmation,
      showAppointment,
      isCallEstablished,
      confirmEmail,
      getProfilePicture,
      onEnterCode,
    };
  },
  beforeUnmount() {

    // stop updating the duration of the calls
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }
};
</script>

<template>

<div v-if="state.visitors.length == 0" class="bg-light rounded border mt-5 mb-3 py-3 text-center fs-5 lead">
  <span>Aktuell befindet sich niemand im Wartebereich</span>
</div>

<div v-if="state.visitors.length > 0">

<div class="bg-primary rounded border mt-5 mb-3 py-3 text-center fs-5 lead text-white">
  <span>Sie haben <span class="fw-bold">{{ state.visitors.length }}</span> Patienten im Wartebereich</span>
</div>

<div class="row g-0 mt-2">
  <div class="col-3 card m-2" v-for="visitor in state.visitors" :key="visitor">

    <img v-if="visitor.patient.gender == 'Male'" src="../../assets/user-male.svg" style="width:6rem;height:6rem" class="mx-auto rounded-circle border border-primary border-3 mt-3">
    <img v-if="visitor.patient.gender == 'Female'" src="../../assets/user-female.svg" style="width:6rem;height:6rem" class="mx-auto rounded-circle border border-primary border-3 mt-3">
    <img v-if="visitor.patient.gender == 'Diverse'" src="../../assets/user-diverse.svg" style="width:6rem;height:6rem" class="mx-auto rounded-circle border border-primary border-3 mt-3">

    <div class="card-body text-center">
      <h5 class="card-title">{{ visitor.patient.name + ' ' + visitor.patient.familyName }}</h5>
      <h6 class="card-subtitle mb-2 text-muted">{{ $fmt.fromFormatToDate(visitor.patient.birthdate, "yyyy-MM-dd") }}</h6>

      <div class="mt-4">
          <i class="bi-calendar2-check" role="img" aria-label="Schedule"></i>
          <span class="ms-2">{{ $fmt.fromMillisToDateTime(visitor.slotAt) }}</span>
      </div>

      <div v-if="!visitor.acceptedAt" class="mt-2">
        <div class="spinner-grow spinner-grow-sm text-primary" role="status"></div>
        <span class="align-middle ms-2">Seit {{ visitor.waiting }} bereit</span>
      </div>

    </div>

    <div class="card-body text-center">

      <a v-if="!visitor.acceptedAt" :href="visitor.meetingUrl" target="confrere" class="btn btn-primary">
        <i class="bi-telephone me-2" role="img" aria-label="Schedule"></i>
        Annehmen
      </a>

      <a v-if="visitor.acceptedAt" href="#" class="btn btn-primary disabled">
        <i class="bi-telephone me-2" role="img" aria-label="Schedule"></i>
        Angenommen
      </a>

    </div>

  </div>

</div>
</div>

</template>

<script>

import { reactive } from 'vue'

import api from '@/modules/api.js';
import format from '@/modules/format.js';
import log from '@/modules/logging.js';
import utils from '@/modules/utils.js';

export default {
  name: 'Waitingroom',
  setup() {

    const state = reactive({
      subscriberId: utils.uuid(),
      polling: true,
      visitors: [],
      interval: null,
    });

    function pollVisitors() {
      if (state.polling) {
        api.pollVisitors(state.subscriberId).
          then(response => {
            postProcessResponse(response);
            pollVisitors();
          }).catch(error => {
            log.trace("Waitingroom:pollVisitors() Error: {}", error);
            pollVisitors();
          });
      }
    }

    function postProcessResponse(response) {

      log.trace("WaiingRoom:postProcessResponse() Processing visitors: {}", response);
      state.visitors = response;

      //
      // clear interval
      if (state.visitors.length == 0 && state.interval) {
        clearInterval(state.interval);
        state.interval = null;
      }

      //
      // setup interval to calculate waiting time
      if (state.visitors.length > 0 && !state.interval) {
        state.interval = setInterval(() => {
          state.visitors.forEach(visitor => {
            visitor.waiting = format.toDuration(visitor.enteredAt, new Date().getTime(), false);
          });
        }, 1000);
      }
    }

    pollVisitors();

    return {
      state
    };
  },
  beforeUnmount() {

    // stop polling for visitor updates
    this.state.polling = false;

    // stop calculating the duration of the waiting time
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  },
};
</script>
<template>

<div class="row w-100 vh-100">

  <!-- show logo or some image here -->
  <div class="col-lg-6 d-flex flex-column justify-content-center bg-info">
    <img src="@/assets/viagen-logo.svg" class="mx-auto d-block" width="100" height="100">
    <p class="display-4 mx-auto">viagen</p>
  </div>

  <!-- the login form -->
  <div class="col-lg-6 d-flex flex-column justify-content-center bg-secondary text-white">
    
    <div class="mx-auto" style="width: 25rem;">

      <h1 class="display-2 text-center mb-5">Anmelden</h1>

      <form @submit.prevent="login">

        <label class="mt-4 mb-1" for="email">Email</label>
        <div class="input-group">
          <input type="text" class="form-control" v-model="state.email" id="email" autocomplete="username" :disabled="state.busy">
          <span class="input-group-text bg-white" id="basic-addon1"><i class="bi bi-at"/></span>
        </div>

         <label class="mt-4 mb-1" for="password">Passwort</label>
         <div class="input-group">
          <input type="password" class="form-control" v-model="state.pass" id="password" autocomplete="current-password" :disabled="state.busy">
          <span class="input-group-text bg-white"><i class="bi bi-lock"/></span>
        </div>

        <p v-if="state.error" class="error text-center mt-3">Anmeldedaten sind nicht korrekt</p>

        <button class="btn btn-primary mt-5 w-100" type="submit" :disabled="state.busy">
          <span v-if="state.busy" class="spinner-border spinner-border-sm text-light me-2"></span>
          Anmelden
        </button>

      </form>
    </div>

  </div>

</div>

</template>

<script>

import { reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import auth from '../modules/auth.js';

export default {
  name: 'Login',
  setup() {

    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      email: '',
      pass: '',
      error: false,
      busy: false
    });

    function login() {
      state.busy = true;
      auth.login(state.email, state.pass, loggedIn => {
        state.busy = false;
        if (!loggedIn) {
          state.error = true
        }
        else {
          router.replace(route.query.redirect || '/')
        }
      })
    }

    return {
      state,
      login
    }
  }
}
</script>

<style scoped>
</style>

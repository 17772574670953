<template>

<div class="row">

  <!-- Date Picker -->
  <div class="col-xl-8" :class="{ 'col-xl-8': state.bookedAt, 'col-xl-12' : !state.bookedAt }">

    <!-- Year & Month Navigation -->
    <div class="mb-2 lead align-middle">
        <span>
          {{ months[state.month] + ' ' + state.year }}
        </span>
        <i @click="onPrevMonth" class="btn bi-chevron-left" ></i>
        <i @click="onNextMonth" class="btn bi-chevron-right"></i>
    </div>

    <!-- Choose day in Calendar -->
    <table class="table table-sm mb-4">
      <thead>
        <tr class="text-center">
          <th scope="col">Mo</th>
          <th scope="col">Di</th>
          <th scope="col">Mi</th>
          <th scope="col">Do</th>
          <th scope="col">Fr</th>
          <th v-if="$props.shortWeek === false" scope="col">Sa</th>
          <th v-if="$props.shortWeek === false" scope="col">So</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="w in 6" :key="w">
          <td class="text-center pt-2 pb-2" height="2rem" v-for="d in ($props.shortWeek === false ? 7 : 5)" :key="d">
              <!-- day is in the past and can not be booked at all -->
              <span v-if="isInPast(state.year, state.month, w - 1, d - 1)" class="dayInPast" style="height: 2rem">
                {{ asDayInMonthStr(state.year, state.month, w - 1, d - 1) }}
              </span>
              <!-- slot is available at this day -->
              <span v-else-if="isSlotAvailable(state.year, state.month, w - 1, d - 1)" class="slotAvailable" :class="{ slotDateBooked : isBookedAtDate(state.year, state.month, w - 1, d - 1) }" @click="setBookedAtDate(state.year, state.month, w - 1, d - 1)">
                {{ asDayInMonthStr(state.year, state.month, w - 1, d - 1) }}
              </span>
              <!-- day is in the future - but no slot available -->
              <span v-else class="noSlotAvailable">
                {{ asDayInMonthStr(state.year, state.month, w - 1, d - 1) }}
              </span>
            </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Time Picker -->
  <transition name="bounce">
  <div v-if="state.bookedAt != null" class="col-xl-4">

    <!-- Current Time -->
    <div class="d-flex lead mb-4">
      Ihr Termin&nbsp;<span class="fw-bold">{{ $fmt.toDate(state.bookedAt) + ' ' + (state.bookedAtTime != null ? $fmt.toTime(state.bookedAtTime) + 'h' : 'Uhrzeit auswählen') }}</span>
    </div>

    <div class="row g-2">
      <div  class="col-4" v-for="timeSlot in state.timeSlots" :key="timeSlot">
        <div class="border border-primary rounded p-2 timeSlot text-center text-primary mb-2" 
                :class="{ timeSlotBooked : isBookedAtTime(timeSlot.hour, timeSlot.min) }" 
                    @click="setBookedAtTime(timeSlot.hour, timeSlot.min)">
          {{ timeSlot.hour + ':' + (timeSlot.min == 0 ? '00' : '30')}}
        </div>
      </div>
    </div>
  </div>
  </transition>

 </div>

</template>

<script>

import { computed, reactive } from 'vue'

import api from '@/modules/api.js';
import log from '@/modules/logging.js';

export default {
  name: 'Calendar',
  props: { 
    bookedAt: {
      type: Date,
      default: null
    },
    shortWeek: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'onSlotSelected'
  ],
  setup(props, { emit }) {

    const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    const state = reactive({

      year: props.bookdAt ? props.bookedAt.getFullYear() : new Date().getFullYear(),
      month: props.bookedAt ? props.bookedAt.getMonth() : new Date().getMonth(),
      slots: [],

      bookedAt: props.bookedAt,
      bookedAtTime: props.bookedAt,

      /**
       * Compute date-time from date and time fields
       */
      bookedAtDateTime: computed(() => {
        return new Date(state.bookedAt.getFullYear(), state.bookedAt.getMonth(), state.bookedAt.getDate(),
          state.bookedAtTime.getHours(), state.bookedAtTime.getMinutes(), 0, 0);
      }),

      /**
       *  Gets available timeslots for bookedAt day
       */
      timeSlots: computed(() => {
        let timeSlots = [];
        state.slots.forEach(slot => {
            let slotStartAt = new Date(slot.at);
            if (datesAreOnSameDay(state.bookedAt, slotStartAt)) {
              timeSlots.push({ "hour": slotStartAt.getHours(), "min": slotStartAt.getMinutes() });  
            }
        });
        return timeSlots;
      }),
    });

    function datesAreOnSameDay(first, second) {
      return (first.getFullYear() == second.getFullYear() &&
              first.getMonth() == second.getMonth() &&
              first.getDate() == second.getDate());
    }

    /**
     * year: Four digit year
     * month: Zero Based 0-11
     * weekInMonth: Zero Based (0-5)
     * dayOfWeek: Zero Based (0-6)
     */
    function toDate(year, month, weekInMonth, dayOfWeek) {
      var dayInMonth = (weekInMonth * 7 + dayOfWeek) - getDayOffset(year, month) + 1;
      return dayInMonth > 0 && dayInMonth <= getDaysInMonth(year, month) ? new Date(year, month, dayInMonth) : null;
    }

    function asDayInMonthStr(year, month, weekInMonth, dayOfWeek) {
      var date = toDate(year, month, weekInMonth, dayOfWeek);
      return date != null ? date.getDate() : '';
    }

    function setBookedAtDate(year, month, weekInMonth, dayOfWeek) {
      state.bookedAt = toDate(year, month, weekInMonth, dayOfWeek);
      state.bookedAtTime = null;
      emit('onSlotSelected', null);
    }

    function isBookedAtDate(year, month, weekInMonth, dayOfWeek) {
      if (!state.bookedAt) {
        return false;
      }
      var date = toDate(year, month, weekInMonth, dayOfWeek);
      return date.getFullYear() === state.bookedAt.getFullYear() && date.getMonth() === state.bookedAt.getMonth() && date.getDate() === state.bookedAt.getDate();
    }

    function setBookedAtTime(hour, minute) {
      state.bookedAtTime = new Date();
      state.bookedAtTime.setHours(hour);
      state.bookedAtTime.setMinutes(minute);
      emit('onSlotSelected', state.bookedAtDateTime);
    }

    function isBookedAtTime(hour, minute) {
      return state.bookedAtTime != null && hour === state.bookedAtTime.getHours() && minute === state.bookedAtTime.getMinutes();
    }

    function isInPast(year, month, weekInMonth, dayOfWeek) {
      const date = toDate(year, month, weekInMonth, dayOfWeek);
      return date < new Date();
    }

    function isSlotAvailable(year, month, weekInMonth, dayOfWeek) {

      var at = toDate(year, month, weekInMonth, dayOfWeek);

      //
      // checks, if we are dealing with a weekend day here
      if (at.getDay() == 6 || at.getDay() == 0) {
        return false;
      }

      //
      // check for slots on given day
      for (var slot of state.slots) {
        let slotAt = new Date(slot.at);
        if (slotAt.getFullYear() == at.getFullYear() && slotAt.getMonth() == at.getMonth() && slotAt.getDate() == at.getDate()) {
            return true;
        }
      }

      return false;
    }

    function getDayOffset(year, month) {
      return getFirstDayOfMonth(year, month).getDay() == 0 ? 6 : (getFirstDayOfMonth(year, month).getDay() -1);
    }

    function getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    }

    function getDaysInMonth(year, month) {
      return new Date(year, month + 1, 0).getDate();
    }

    function onPrevMonth() {
      if (state.month > 0) {
        state.month--;
      }
      else {
        state.month = 11;
        state.year--;
      }
      querySlots();
    }

    function onNextMonth() {
      if (state.month < 11) {
        state.month++;
      }
      else {
        state.month = 0;
        state.year++;
      }
      querySlots();
    }

    function querySlots() {
      log.trace("Calendar:querySlots() from {} to {} ", new Date(state.year, state.month, 1), new Date(state.year, state.month + 1, 0, 23, 59, 59));
      api.queryAvailableSlots(new Date(state.year, state.month, 1), new Date(state.year, state.month + 1, 1))
        .then(data => {
          state.slots = data;
          log.trace("Calendar:querySlots(): Fetched slots: {}", state.slots);
        });
    }

    querySlots();

    return {
      months,
      state,
      onPrevMonth,
      onNextMonth,
      getDayOffset,
      asDayInMonthStr,
      isInPast,
      isSlotAvailable,
      setBookedAtDate,
      isBookedAtDate,
      setBookedAtTime,
      isBookedAtTime
    };
  }
};
</script>

<style scoped>

.dayInPast {

    display: block;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;

    font-size: 1em;
    color: darkgray;

    margin: auto;
}

.slotAvailable {

    display: block;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;

    -moz-border-radius: 50%;
    border-radius: 50%;

    background-color: #17C3B2;
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    color: white;

    margin: auto;
    cursor: pointer;
}

.slotAvailable:hover {
    background-color: #227c9d;
}

.noSlotAvailable {

    display: block;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;

    font-size: 1em;
    font-weight: 600;

    margin: auto;
}

.slotDateBooked {
    background-color: #227c9d !important;
}

.timeSlot {
    cursor: pointer;
}

.timeSlot:hover {
  background-color: #17C3B2;
  color: white !important;
}

.timeSlotBooked {
  background-color: #227c9d !important;
  border-color: #227c9d !important;
  color: white !important;
}

.bounce-enter-active {
  animation: bounce-in .5s ease-out both;
}

@keyframes bounce-in {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(0.5);
  }
  100% {
    transform: scaleX(1);
  }
}

</style>
function mapStatusToMessage(order) {

    if (order.outboundStatus == 'Pending' || order.inboundStatus == 'Pending') {
        return "Bereit zur Kommissionierung";
    }

    if (order.outboundStatus != 'Delivered') {
        //
        // map outbound status
        switch (order.outboundStatus) {
            case 'Pending': return "Abnahmesystem bereit zur Kommissionierung";
            case 'Commissioned': return "Abnahmesystem bereit für Zusendung";
            case 'Posted': return "Abnahmesystem ist unterwegs";
            case 'Delivered': return "Abnahmesystem an Patient geliefert";
        }
    }
    else {
        //
        // map inbound status
        switch (order.inboundStatus) {
            case 'Pending': return "Abnahmesystem bereit zur Kommissionierung";
            case 'Commissioned': return "Abnahmesystem an Patient geliefert. Bereit für Rücksendung";
            case 'Posted': return "Probe ist unterwegs zurück ins Labor";
            case 'Delivered': return "Probe ist im Labor angekommen";
        }
    }
}

function isOrderReadyForCommission(order) {
    return order.outboundStatus == 'Pending' || order.inboundStatus == 'Pending';
}

function isOrderInShippingToPatient(order) {
    return !isOrderReadyForCommission(order) && (order.outboundStatus == 'Commissioned' || order.outboundStatus == 'Posted' || order.outboundStatus == 'Delivered') && !isOrderInShippingToLab(order);
}

function isOrderInShippingToLab(order) {
    return order.inboundStatus == 'Posted' || order.inboundStatus == 'Delivered';
}

function isOrderIsFinished(order) {
    return order.inboundStatus == 'Delivered';
}

export default {   
    mapStatusToMessage,
    isOrderReadyForCommission,
    isOrderInShippingToPatient,
    isOrderInShippingToLab,
    isOrderIsFinished
};
